import { getEnvVariableValue } from '@experiences/util';
import { useMemo } from 'react';
import {
    matchPath,
    useLocation,
} from 'react-router-dom';

import { useIsSettingsRouteMatched } from '../../util/RouteUtil';
import * as RouteNames from '../constants/RouteNames';
import useIsProfileRoute from './useIsProfileRoute';

enum Entitlement {
    APP_TESTER = 'Ubl.AppTesterNamedUser',
    APP_TEST_DEVELOPER = 'Ubl.AppTestDevNamedUser',
}

const useProductName = () => {

    const isProfileRoute = useIsProfileRoute();
    const isSettingsRoute = useIsSettingsRouteMatched();
    const { pathname } = useLocation();
    const isTenantCreateRoute = matchPath({ path: RouteNames.TenantCreate }, pathname);

    const showTrademark = useMemo(
        () => !isProfileRoute && !isSettingsRoute && !isTenantCreateRoute,
        [ isProfileRoute, isSettingsRoute, isTenantCreateRoute ],
    );

    const productName = useMemo<string>(() => {
        if (isProfileRoute) {
            return '';
        }

        if (isSettingsRoute || isTenantCreateRoute) {
            return 'Administration';
        }
        const isTestCloudUser =
            window.PortalShell?.AccountAndTenants.hasAccountEntitlement(Entitlement.APP_TESTER as any) ||
            window.PortalShell?.AccountAndTenants.hasAccountEntitlement(Entitlement.APP_TEST_DEVELOPER as any);

        if (isTestCloudUser) {
            return 'Test Cloud';
        }

        return getEnvVariableValue('PRODUCT_NAME');
    }, [ isProfileRoute, isSettingsRoute, isTenantCreateRoute ]);

    return {
        productName,
        showTrademark,
    };
};

export default useProductName;

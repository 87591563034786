import type { IRefreshable } from '@experiences/interfaces';
import { Entity } from '@experiences/interfaces';
import {
    UiDataContextProvider,
    UiSuspensefulOutlet,
} from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
    BulkUserInvite,
    GroupAllocationRule,
    Groups,
    GroupsAdd,
    GroupsEdit,
    Robots,
    RobotsAddEdit,
    UserAddEdit,
    UserInviteEdit,
    Users,
    UsersUserAddLicenseAllocation,
    UsersUserEditLicenseAllocation,
    UsersUserLicenseInheritance,
} from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider from '../../common/providers/BreadcrumbProvider';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';
import ProfileEditLicenses from './profile/ProfileEditLicenses';

const UsersPageComponent = React.lazy(() => import('./UsersPageComponent'));
const AddUserAllocationComponent = React.lazy(() => import('../licensing/subcomponents/AddUserAllocationComponent'));
const EditUserAllocationComponent = React.lazy(() => import('../licensing/subcomponents/EditUserAllocationComponent'));
const InheritedLicenseComponent = React.lazy(() => import('../licensing/subcomponents/InheritedLicenseComponent'));
const AddEditRobotAccountComponent = React.lazy(() => import('./subcomponents/AddEditRobotAccountComponent'));
const BulkInviteUserComponent = React.lazy(() => import('./subcomponents/BulkInviteUserComponent'));
const CreateEditGroupComponent = React.lazy(() => import('./subcomponents/CreateEditGroupComponent'));
const InviteEditUserComponent = React.lazy(() => import('./subcomponents/InviteEditUserComponent'));
const AddEditGroupAllocationComponent = React.lazy(() => import('../licensing/subcomponents/AddEditGroupAllocationComponent'));
const ProfileComponent = React.lazy(() => import('./profile/ProfileComponent'));
const ProfileAddGroupsOrMembers = React.lazy(() => import('./profile/ProfileAddGroupsOrMembers'));

const AddEditUserComponent = React.lazy(() => import('./subcomponents/AddEditUserComponent'));

const EntitiesProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();

    const breadcrumbs = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: organizationName,
        },
        {
            index: 1,
            link: routePaths.adminIdentitiesUsers,
            name: translate({ id: 'CLIENT_ACCOUNTS_AND_LOCAL_GROUPS' }),
        },
        {
            index: 1,
            link: routePaths.adminIdentitiesGroups,
            name: translate({ id: 'CLIENT_ACCOUNTS_AND_LOCAL_GROUPS' }),
        },
        {
            index: 1,
            link: routePaths.adminIdentitiesRobots,
            name: translate({ id: 'CLIENT_ACCOUNTS_AND_LOCAL_GROUPS' }),
        },
    ], [ organizationName, translate ]);

    return <BreadcrumbProvider breadcrumbs={breadcrumbs}>
        {children}
    </BreadcrumbProvider>;
};

const NewAccountAndGroupsRoutes = () => <UiDataContextProvider<IRefreshable> initialState={{ refresh: false }}>
    <ProfileComponent />
    <UiSuspensefulOutlet />
</UiDataContextProvider>;

export const AdminIdentitiesRoutes: UiRouteObject = {
    path: routePaths.adminIdentities,
    element: <EntitiesProvider>
        <UiSuspensefulOutlet />
    </EntitiesProvider>,
    children: [
        {
            path: routePaths.adminIdentitiesUsers,
            redirectUrls: [ Users ],
            element: <UiDataContextProvider<IRefreshable> initialState={{ refresh: false }}>
                <UsersPageComponent entityType={Entity.USERS} />
                <UiSuspensefulOutlet />
            </UiDataContextProvider>,
            children: [
                {
                    path: routePaths.adminIdentitiesUsersAdd,
                    redirectUrls: [ UserInviteEdit.replace(':type', 'invite') ],
                    element: <InviteEditUserComponent type="invite" />,
                    explicitEnvironments: [ 'cloud' ],
                },
                {
                    path: routePaths.adminIdentitiesUsersAdd,
                    redirectUrls: [ UserAddEdit.replace(':type', 'add') ],
                    element: <AddEditUserComponent type="add" />,
                    explicitEnvironments: [ 'k8s' ],
                },
                // TODO: Refactor InviteEditUserComponent to not use state
                {
                    path: routePaths.adminIdentitiesUsersEdit,
                    redirectUrls: [ UserInviteEdit.replace(':type', 'edit') ],
                    element: <InviteEditUserComponent type="edit" />,
                    explicitEnvironments: [ 'cloud' ],
                },
                {
                    path: routePaths.adminIdentitiesUsersEdit,
                    redirectUrls: [ UserAddEdit.replace(':type', 'edit') ],
                    element: <AddEditUserComponent type="edit" />,
                    explicitEnvironments: [ 'k8s' ],
                },
                {
                    path: routePaths.adminIdentitiesUsersBulkAdd,
                    redirectUrls: [ BulkUserInvite ],
                    element: <BulkInviteUserComponent />,
                },
                {
                    path: routePaths.adminIdentitiesUsersLicenseAdd,
                    redirectUrls: [ UsersUserAddLicenseAllocation ],
                    element: <AddUserAllocationComponent />,
                },
                {
                    path: routePaths.adminIdentitiesUsersLicenseEdit,
                    redirectUrls: [ UsersUserEditLicenseAllocation ],
                    element: <EditUserAllocationComponent />,
                },
                {
                    path: routePaths.adminIdentitiesUsersLicenseInheritance,
                    redirectUrls: [ UsersUserLicenseInheritance ],
                    element: <InheritedLicenseComponent />,
                },
            ],
        },
        {
            path: routePaths.adminIdentitiesGroups,
            redirectUrls: [ Groups ],
            element: <UiDataContextProvider<IRefreshable> initialState={{ refresh: false }}>
                <UsersPageComponent entityType={Entity.GROUPS} />
                <UiSuspensefulOutlet />
            </UiDataContextProvider>,
            children: [
                {
                    path: routePaths.adminIdentitiesGroupsAdd,
                    redirectUrls: [ GroupsAdd.replace(':type', 'add') ],
                    element: <CreateEditGroupComponent type="add" />,
                },
                {
                    path: routePaths.adminIdentitiesGroupsEdit,
                    redirectUrls: [ GroupsEdit.replace(':type', 'edit') ],
                    element: <CreateEditGroupComponent type="edit" />,
                },
                {
                    path: routePaths.adminIdentitiesGroupsLicenseAdd,
                    redirectUrls: [ GroupAllocationRule.replace(':type', 'add') ],
                    element: <AddEditGroupAllocationComponent type="add" />,
                },
                {
                    path: routePaths.adminIdentitiesGroupsLicenseEdit,
                    redirectUrls: [ GroupAllocationRule.replace(':type', 'edit') ],
                    element: <AddEditGroupAllocationComponent type="edit" />,
                },
            ],
        },
        {
            path: routePaths.adminIdentitiesRobots,
            redirectUrls: [ Robots ],
            element: <UiDataContextProvider<IRefreshable> initialState={{ refresh: false }}>
                <UsersPageComponent entityType={Entity.ROBOTS} />
                <UiSuspensefulOutlet />
            </UiDataContextProvider>,
            children: [
                {
                    path: routePaths.adminIdentitiesRobotsAdd,
                    redirectUrls: [ RobotsAddEdit.replace(':type', 'add') ],
                    element: <AddEditRobotAccountComponent type="add" />,
                },
                {
                    path: routePaths.adminIdentitiesRobotsEdit,
                    redirectUrls: [ RobotsAddEdit.replace(':type', 'edit') ],
                    element: <AddEditRobotAccountComponent type="edit" />,
                },
            ],
        },
        {
            path: routePaths.adminIdentitiesProfileGroupMemberships,
            element: <NewAccountAndGroupsRoutes />,
            children: [
                {
                    path: routePaths.adminIdentitiesProfileGroupMembershipsAdd,
                    element: <ProfileAddGroupsOrMembers />,
                },
            ],
        },
        {
            path: routePaths.adminIdentitiesProfileMembers,
            element: <NewAccountAndGroupsRoutes />,
            children: [
                {
                    path: routePaths.adminIdentitiesProfileMembersAdd,
                    element: <ProfileAddGroupsOrMembers />,
                },
            ],
        },
        {
            path: routePaths.adminIdentitiesProfileLicenses,
            element: <NewAccountAndGroupsRoutes />,
            children: [
                {
                    path: routePaths.adminIdentitiesProfileLicensesEdit,
                    element: <ProfileEditLicenses />,
                },
                {
                    path: routePaths.adminIdentitiesProfileLicensesInheritance,
                    element: <InheritedLicenseComponent />,
                },
            ],
        },
        {
            path: routePaths.adminIdentitiesProfileAccess,
            element: <NewAccountAndGroupsRoutes />,
        },
        {
            path: routePaths.adminIdentitiesProfileInfo,
            element: <NewAccountAndGroupsRoutes />,
        },
    ],
};

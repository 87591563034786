import type {
    IEffectiveAccessDto,
    IPagination,
    IPermissionModel,
} from '@experiences/interfaces';

import { mapPagination } from '../orchestrator/TagsUtil';
import {
    get,
    post,
} from '../utility/Requests';

export async function getEffectiveAccess({
    url, pagination, payload,
}: { url: string; pagination: IPagination; payload: any }) {
    const paginationProps = mapPagination(pagination);
    const searchQueryParams = new URLSearchParams({
        top: paginationProps.take?.toString() ?? '10',
        skip: paginationProps.skip?.toString() ?? '0',
    });
    return post<IEffectiveAccessDto>(
        `${url}?${searchQueryParams.toString()}`,
        {
            body: {
                ...payload,
                roleNameStartsWith: pagination.searchTerm,
            },
            stripPortalPath: true,
        },
    );
}

export async function getRoleById({ roleId }: { roleId: string }) {
    return get<IPermissionModel>(`/pap_/api/roles/${roleId}`, { stripPortalPath: true });
}

import { UiText } from '@experiences/ui-common';
import { useUserReadableTime } from '@experiences/util';
import CircleIcon from '@mui/icons-material/Circle';
import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import {
    ApDataGridColumn,
    ApDataGridColumnDropdownFilter,
    ApDataGridFooter,
    ApDataGridHeader,
    ApDataGridHeaderButton,
    ApDataGridRowActions,
    ApDataGridRowButton,
    ApDataGridWrapper,
    ApTooltip,
} from '@uipath/portal-shell-react';
import type {
    DataManager,
    FilterManager,
    ISortModel,
    SelectionManager,
} from '@uipath/portal-shell-types/components/angular-elements';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';

import { notificationType } from '../../common/constants/Constant';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import {
    getUserNotificationSubscription,
    UnsubscribeTenantNotificationSubscription,
    updateUserNotificationTopicSubscription,
    userNotificationSubscriptionUri,
} from '../../services/notification-preferences';
import type { ODataQueryOptions } from '../../services/notifications';
import {
    deleteNotification,
    deleteNotifications,
    getUserNotificationEntries,
    notificationEntryUri,
    updateNotificationForUnread,
} from '../../services/notifications';
import { accountGlobalId } from '../../store/selectors';
import type {
    INotificationPublisherDto,
    IUpdateUserSubscriptionsRequestData,
} from '../notificationSettings/interfaces/notificationSettings';
import { useTenantsContext } from '../tenants/TenantsContextProvider';
import {
    getFilterQuery,
    getPublisherUsingTopicId,
    getServiceList,
    getTimeTick,
    removeFilters,
} from './helper/NotificationPageHelper';
import type {
    IFilterDataType,
    IFilterQueryParamType,
    INotificationEntry,
    INotificationReadRequest,
} from './interfaces/notifications';
import {
    Duration,
    INotificationActionType,
    NotificationSeverity,
} from './interfaces/notifications';
import NotificationCellComponent from './NotificationCellComponent';
import { NotificationMoreRowActionsComponent } from './NotificationMoreRowActionsComponent';

const useStyles = makeStyles((theme) =>
    createStyles({
        tabsContainer: { marginBottom: Tokens.Padding.PadL },
        heading: {
            fontSize: Tokens.FontFamily.FontLSize,
            fontWeight: Tokens.FontFamily.FontWeightSemibold,
            paddingBottom: Tokens.Padding.PadXxxl,
        },
        showOnlyUnread: {
            marginLeft: Tokens.Padding.PadM,
            fontSize: `${Tokens.FontFamily.FontMSize} !important`,
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold} !important`,
            color: `${theme.palette.semantic.colorForeground} !important`,
        },
        iconContainer: { '&:hover': { backgroundColor: `${theme.palette.semantic.colorErrorText} !important` } },
        circleiconClass: {
            width: '.5em !important',
            height: '.5em !important',
            fontSize: '1rem !important',
            margin: '6px',

        },
        link: {
            alignSelf: 'flex-start',
            marginTop: '24px',
            fontSize: Tokens.FontFamily.FontMSize,
            fontWeight: Tokens.FontFamily.FontWeightSemibold,
            lineHeight: '20px',
            cursor: 'pointer',
        },
    }),
);
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_INDEX = 0;
const publisherKey = 'publisherId';
const tenantKey = 'tenantId';
const topicKey = 'topicId';
const categoryKey = 'category';
const publishedOnKey = 'publishedOn';

const NotificationPageComponentV2: React.FC = () => {

    const [ searchParams, setSearchParams ] = useSearchParams();

    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const allFilterOption = useMemo(() => ({
        label: translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
        value: translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
    }), [ translate ]);

    const partitionGlobalId = useSelector(accountGlobalId);
    const { userReadableTime } = useUserReadableTime();
    const {
        selectedTenant: { id: tenantId }, tenantsWithoutServices: tenantsData,
    } = useTenantsContext();

    const tenantName = localStorage.getItem('PORTAL_CURRENT_TENANT') ?? '';
    const [ tenants, setTenants ] = useState<IFilterDataType[]>([ ]);
    const [ sortOpts, setSortOpts ] = useState<Array<ISortModel<INotificationEntry>>>([
        {
            field: publishedOnKey,
            direction: 'desc',
            title: publishedOnKey,
        },
    ]);
    const [ size, setPageSize ] = useState(DEFAULT_PAGE_SIZE);
    const [ dataMgr, setDataMgr ] = useState<DataManager<INotificationEntry>>();
    const [ loadingGrid, setloadingGrid ] = useState(false);

    const [ publisherTypes, setPublisherTypes ] = useState<IFilterDataType[]>([ ]);
    const [ topicTypes, setTopicTypes ] = useState<IFilterDataType[]>([ ]);
    const [ topicGroupTypes, setGroupTopicTypes ] = useState<IFilterDataType[]>([ ]);
    const [ selectionMgr, setSelectionMgr ] = useState<SelectionManager<INotificationEntry>>();
    const [ filterMgr, setFilterMgr ] = useState<FilterManager<INotificationEntry>>();
    const [ index, setPageIndex ] = useState(DEFAULT_PAGE_INDEX);

    const [ selectedFilters, setSelectedFilters ] = useState<{
        topicGroup?: IFilterDataType;
        topic?: IFilterDataType;
        publisher?: IFilterDataType;
        tenant?: IFilterDataType;
        severity?: IFilterDataType;
        publishedOn?: IFilterDataType;
    }>({});

    const setSelectedTopicGroupFilter = useCallback((filter?: IFilterDataType) => {
        setSelectedFilters(prev => ({
            ...prev,
            topicGroup: filter,
        }));
    }, []);

    const setSelectedTopicFilter = useCallback((filter?: IFilterDataType) => {
        setSelectedFilters(prev => ({
            ...prev,
            topic: filter,
        }));
    }, []);

    const setSelectedTenantFilter = useCallback((filter?: IFilterDataType) => {
        setSelectedFilters(prev => ({
            ...prev,
            tenant: filter,
        }));
    }, []);

    const setSelectedSeverityFilter = useCallback((filter?: IFilterDataType) => {
        setSelectedFilters(prev => ({
            ...prev,
            severity: filter,
        }));
    }, []);

    const setSelectedPublishedOnFilter = useCallback((filter?: IFilterDataType) => {
        setSelectedFilters(prev => ({
            ...prev,
            severity: filter,
        }));
    }, []);

    const setSelectedPublisherFilter = useCallback((filter?: IFilterDataType) => {
        setSelectedFilters(prev => ({
            ...prev,
            publisher: filter,
        }));
    }, []);

    const publishedOnTimeFilter = useMemo(() => ([
        {
            label: translate({ id: 'CLIENT_LAST_HOUR' }),
            value: Duration.LastHour.toString(),
        }, {
            label: translate({ id: 'CLIENT_TOTAL_RUN_TODAY' }),
            value: Duration.Last24Hour.toString(),
        }, {
            label: translate({ id: 'CLIENT_LAST_WEEK' }),
            value: Duration.LastWeek.toString(),
        }, {
            label: translate({ id: 'CLIENT_LAST_30_DAYS' }),
            value: Duration.LastMonth.toString(),
        },
    ]), [ translate ]);
    const severityTypesFilters: IFilterDataType[] = useMemo(() => [
        {
            label: translate({ id: 'SEVERITY_INFO' }),
            value: NotificationSeverity.Info,
        },
        {
            label: translate({ id: 'SEVERITY_SUCCESS' }),
            value: NotificationSeverity.Success,
        },
        {
            label: translate({ id: 'SEVERITY_WARN' }),
            value: NotificationSeverity.Warn,
        },
        {
            label: translate({ id: 'SEVERITY_ERROR' }),
            value: NotificationSeverity.Error,
        },
        {
            label: translate({ id: 'SEVERITY_FATAL' }),
            value: NotificationSeverity.Fatal,
        },
    ], [ translate ]);
    const [ onlyUnread, setOnlyUnread ] = useState(false);
    const createNotification = useUiSnackBar();
    const [ filterQueryParamType, setFilterQueryParamType ] = useState<IFilterQueryParamType[]>([]);
    const [ searchTermText, setSearchTerm ] = useState('');
    const defaultOptions: ODataQueryOptions = useMemo(() => ({
        top: DEFAULT_PAGE_SIZE,
        skip: 0,
        orderBy: [ 'publishedOn desc' ],
        count: true,
    }), []);
    const [ queryOptions, setQueryOptions ] = useState(defaultOptions);
    const [ componentToSkip ] = useState<string[]>([ 'path', 'button', 'svg', 'input' ]);

    const showProfileError = useCallback(
        (e: Error) => {
            const errorMessage = e?.message;
            createNotification(`${translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_UPDATE_FAILED' })} ${errorMessage ? ' - ' + errorMessage : ''}`, notificationType.ERROR, {
                horizontal: 'right',
                vertical: 'top',
            });
        },
        [ createNotification, translate ],
    );

    const { data: publishersData } = useSWR(
        {
            requestUri: userNotificationSubscriptionUri,
            selectedTenantId: tenantId,
        },
        getUserNotificationSubscription,
    );

    const {
        data, isValidating: notificationsLoading, mutate: notificationMutate,
    } = useSWR(
        {
            url: notificationEntryUri,
            selectedAccountId: partitionGlobalId,
            selectedTenantId: tenantId,
            options: queryOptions,
        },
        getUserNotificationEntries,
    );

    const setParamsArrayValueV2 = useCallback((key: string, value?: string, keysToRemove?: string[]) => {
        const newSearchParams = new URLSearchParams(window.location.search);
        newSearchParams.delete(key);
        keysToRemove?.forEach((dkey) => newSearchParams.delete(dkey));
        if (value) {
            newSearchParams.set(key, value);
        }
        setSearchParams(newSearchParams);
    }, [ setSearchParams ]);

    const setTopicGroupFilter = useCallback((filterStores: IFilterQueryParamType[], topicGroup: string | null, parentPublisher: IFilterDataType) => {
        const selectedFilterGroup = parentPublisher.childs?.find(x => x.value === topicGroup);
        let filterValueStores: IFilterQueryParamType[] = [];
        if (selectedFilterGroup) {

            filterValueStores = removeFilters(filterStores, [ topicKey ]);

            if (selectedFilterGroup.childs?.length !== 0) {
                setGroupTopicTypes(parentPublisher.childs ?? []);
                setSelectedTopicGroupFilter({
                    label: selectedFilterGroup.label,
                    value: selectedFilterGroup.value,
                });
                setTopicTypes(selectedFilterGroup.childs ?? []);
                const filterData: IFilterQueryParamType = {
                    compValue: '',
                    operand: topicKey, // prop,
                    operator: 'or',
                    paramList: selectedFilterGroup.childs,
                };
                filterValueStores.push(filterData);
            }
        }
        return filterValueStores;
    }, [ setSelectedTopicGroupFilter ]);

    const setTopicDefaultFilterV2 = useCallback((filterStores: IFilterQueryParamType[], topic: string) => {
        const [ selectedPublisher, selectedFilterGroup, selectedTopic ] = getPublisherUsingTopicId(topic, publisherTypes);
        let filterValueStores: IFilterQueryParamType[] = [];
        if (selectedPublisher && selectedTopic) {
            setSelectedPublisherFilter({
                label: selectedPublisher?.label,
                value: selectedPublisher?.value,
            });
            filterValueStores = removeFilters(filterStores, [ publisherKey ]);
            const filterData: IFilterQueryParamType = {
                compValue: selectedPublisher?.value ?? '',
                operand: publisherKey,
                operator: 'eq',
            };
            filterValueStores.push(filterData);

            setGroupTopicTypes(selectedPublisher.childs ?? []);

            setSelectedTopicGroupFilter({
                label: selectedFilterGroup.label,
                value: selectedFilterGroup.value,
            });
            setTopicTypes(selectedFilterGroup.childs ?? []);

            setSelectedTopicFilter({
                label: selectedTopic?.label,
                value: selectedTopic?.value,
            });
        }
        const filterData: IFilterQueryParamType = {
            compValue: topic,
            operand: topicKey,
            operator: 'eq',
        };
        filterValueStores.push(filterData);
        return filterValueStores;
    }, [ publisherTypes, setSelectedPublisherFilter, setSelectedTopicFilter, setSelectedTopicGroupFilter ]);

    const setDefaultFilterQuery = useCallback(({
        publisher,
        topicGroup,
        topic,
        category,
        greaterThan,
        lessThan,
        isRead,
        messageParam,
        filterTenantId,
    }: {
        publisher: string | null;
        topicGroup: string | null;
        topic: string | null;
        category: string | null;
        greaterThan: string | null;
        lessThan: string | null;
        isRead: string | null;
        messageParam: string | null;
        filterTenantId: string | null;
    }) => {
        let filterValueStores = [];
        const selectParentPublisher = publisherTypes.find(x => x.value === publisher);
        if (selectParentPublisher) {
            setSelectedPublisherFilter({
                label: selectParentPublisher?.label,
                value: selectParentPublisher?.value,
            });
            const filterData: IFilterQueryParamType = {
                compValue: selectParentPublisher?.value ?? '',
                operand: publisherKey,
                operator: 'eq',
            };
            filterValueStores.push(filterData);
        }
        if (topicGroup && selectParentPublisher) {

            const selectedGroup = selectParentPublisher.childs?.find(x => x.value === topicGroup);

            if (selectedGroup) {

                filterValueStores = setTopicGroupFilter(filterValueStores, topicGroup, selectParentPublisher);
            }
        }
        if (topic) {
            filterValueStores = setTopicDefaultFilterV2(filterValueStores, topic);
        }

        if (category) {
            const selectParentSeverity = severityTypesFilters.find(x => x.value === category);
            setSelectedSeverityFilter({
                label: selectParentSeverity?.label ?? '',
                value: selectParentSeverity?.value ?? category,
            });
            const filterData: IFilterQueryParamType = {
                compValue: `'${selectParentSeverity?.value}'`,
                operand: categoryKey, // prop,
                operator: 'eq',
            };
            filterValueStores.push(filterData);
        }

        if (greaterThan) {
            const filterData: IFilterQueryParamType = {
                compValue: greaterThan.toString(),
                operand: publishedOnKey, // prop,
                operator: 'ge',
            };
            filterValueStores.push(filterData);
        }
        if (lessThan) {
            const filterData: IFilterQueryParamType = {
                compValue: lessThan.toString(),
                operand: publishedOnKey, // prop,
                operator: 'le',
            };
            filterValueStores.push(filterData);
        }
        if (isRead) {
            const filterData: IFilterQueryParamType = {
                compValue: isRead,
                operand: 'isRead',
                operator: 'eq',
            };
            filterValueStores.push(filterData);
            setOnlyUnread(true);
        }
        if (messageParam) {
            const filterData: IFilterQueryParamType = {
                compValue: messageParam,
                operand: 'messageParam',
                operator: 'contains',
            };
            filterValueStores.push(filterData);
        }
        if (filterTenantId) {

            const filteredTenant = tenantsData.find(x => x.id === filterTenantId);

            if (filteredTenant) {
                setSelectedTenantFilter({
                    label: filteredTenant.name,
                    value: filteredTenant.id,
                });
            }
            const filterData: IFilterQueryParamType = {
                compValue: filterTenantId,
                operand: tenantKey,
                operator: 'eq',
            };
            filterValueStores.push(filterData);
        }
        setFilterQueryParamType(filterValueStores);
        const filtersString = getFilterQuery(filterValueStores);

        if (queryOptions.filter === filtersString) {
            return;
        }

        const changePageIndexOptions: ODataQueryOptions = {
            ...queryOptions,
            filter: filtersString,
        };
        setQueryOptions(changePageIndexOptions);
    }, [
        publisherTypes,
        queryOptions,
        setSelectedPublisherFilter,
        setSelectedSeverityFilter,
        setSelectedTenantFilter,
        setTopicDefaultFilterV2,
        setTopicGroupFilter,
        severityTypesFilters,
        tenantsData,
    ]);

    const updateGridData = useCallback((pageIndex: number, pageSize: number, term: string) => {
        setPageIndex(pageIndex);
        setPageSize(pageSize);
        setSearchTerm(term);
        const filterValueStores = [ ...filterQueryParamType ];
        const itemIndex = filterValueStores.findIndex(x => x.operand === 'messageParam');
        if (itemIndex !== -1) {
            filterValueStores.splice(itemIndex, 1);
        }
        if (term !== '') {
            const filterData: IFilterQueryParamType = {
                compValue: term,
                operand: 'messageParam',
                operator: 'contains',
            };
            filterValueStores.push(filterData);
        }
        setParamsArrayValueV2('messageParam', term);

        setFilterQueryParamType(filterValueStores);
        const filtersString = getFilterQuery(filterValueStores);
        const changePageIndexOptions: ODataQueryOptions = {
            ...queryOptions,
            top: pageSize,
            skip: pageIndex * pageSize,
            orderBy: queryOptions.orderBy,
            filter: filtersString,
        };
        setQueryOptions(changePageIndexOptions);
    }, [ filterQueryParamType, queryOptions, setParamsArrayValueV2 ]);

    const handleHeaderCheckbox = useCallback((isChecked: boolean) => {
        setOnlyUnread(isChecked);
        const filterValueStores = [ ...filterQueryParamType ];
        if (isChecked) {
            const filterData: IFilterQueryParamType = {
                compValue: 'false',
                operand: 'isRead',
                operator: 'eq',
            };
            filterValueStores.push(filterData);
            setParamsArrayValueV2('isRead', 'false');
        } else {
            const isReadindex = filterValueStores.findIndex(x => x.operand === 'isRead');
            filterValueStores.splice(isReadindex, 1);
            setParamsArrayValueV2('isRead');
        }
        setFilterQueryParamType(filterValueStores);
        const filtersString = getFilterQuery(filterValueStores);

        setPageIndex(DEFAULT_PAGE_INDEX);
        const queryCurrOptions: ODataQueryOptions = {
            ...queryOptions,
            top: size,
            skip: DEFAULT_PAGE_INDEX * size,
            filter: filtersString,
        };
        setQueryOptions(queryCurrOptions);
    }, [ filterQueryParamType, queryOptions, setParamsArrayValueV2, size ]);

    const ResetToDefault = useCallback(() => {
        setOnlyUnread(false);
        setPageIndex(DEFAULT_PAGE_INDEX);
        const filterStores: IFilterQueryParamType[] = [];
        setSearchParams(new URLSearchParams());
        setSearchTerm('');
        setSelectedPublisherFilter(allFilterOption);
        setSelectedSeverityFilter(allFilterOption);
        setSelectedPublishedOnFilter(allFilterOption);
        setSelectedTopicGroupFilter(allFilterOption);
        setGroupTopicTypes([]);
        setTopicTypes([]);
        setSelectedTenantFilter(undefined);
        setFilterQueryParamType(filterStores);
        setQueryOptions(defaultOptions);
        filterMgr?.updateCustomFilters(filterMgr?.defaultValueDropdownFilters);
    }, [
        allFilterOption,
        defaultOptions,
        filterMgr,
        setSearchParams,
        setSelectedPublishedOnFilter,
        setSelectedPublisherFilter,
        setSelectedSeverityFilter,
        setSelectedTenantFilter,
        setSelectedTopicGroupFilter,
    ]);

    const handleDelete = useCallback (async () => {
        const selectedItems = selectionMgr?.selected ?? [];
        const deleteItems = selectedItems.map(x => x.id) ?? [];

        await deleteNotifications(deleteItems, tenantId, partitionGlobalId);
        createNotification(translate({ id: 'CLIENT_NOTIFICATION_CLEAR' }), notificationType.SUCCESS, {
            horizontal: 'right',
            vertical: 'top',
        });
        if (data !== undefined) {
            for (const row of selectedItems) {
                data.value = data.value.filter(x => x.id !== row.id);
                data['@odata.count'] = data['@odata.count'] - 1;
                dataMgr?.update(data.value ?? null);
            }

        }
    }, [ createNotification, data, dataMgr, partitionGlobalId, selectionMgr?.selected, tenantId, translate ]);

    const handleToggleRead = useCallback (async () => {
        const request: INotificationReadRequest = {
            notifications: [],
            forceAllRead: false,
        };
        if (data !== undefined) {

            const items: INotificationEntry[] = selectionMgr?.selected ?? [];
            for (const row of items) {
                const originalRow = data.value.find(x => x.id === row.id);
                if (originalRow !== undefined) {
                    request.notifications.push({
                        notificationId: originalRow.id,
                        read: true,
                    });
                }
            }

            await updateNotificationForUnread(request, tenantId, partitionGlobalId);
            createNotification(translate({ id: 'CLIENT_NOTIFICATIONS_READ' }), notificationType.SUCCESS, {
                horizontal: 'right',
                vertical: 'top',
            });
            for (const row of items) {
                const originalRow = data.value.find(x => x.id === row.id);
                if (originalRow !== undefined) {
                    originalRow.isRead = true;
                }
            }
            dataMgr?.update(data.value ?? null);
        }

    }, [ createNotification, data, dataMgr, partitionGlobalId, selectionMgr?.selected, tenantId, translate ]);

    const SelectionItemAction = useCallback(async (actionType: INotificationActionType) => {
        try {
            setloadingGrid(true);
            if (actionType === INotificationActionType.Delete) {
                await handleDelete();
            } else if (actionType === INotificationActionType.ToggleRead) {
                await handleToggleRead();
            }
            setloadingGrid(false);
        } catch (e) {
            setloadingGrid(false);
            showProfileError(e as Error);
        }

    }, [ handleDelete, showProfileError, handleToggleRead ]);

    const changeNotificationRead = useCallback(
        async (entry: {
            event: Event;
            row: any;
        }) => {
            const targetName = (entry.event?.target as HTMLElement)?.localName;
            if (targetName && componentToSkip.find(x => x === targetName)) {
                return;
            }

            if (!entry.row.isRead) {

                const originalSelectRow = data?.value.find(x => x.id === entry.row.id);
                if (originalSelectRow !== undefined) {
                    originalSelectRow.isRead = true;
                }

                setloadingGrid(true);
                const requestPayload: INotificationReadRequest = {
                    notifications: [],
                    forceAllRead: false,
                };

                requestPayload.notifications = [
                    {
                        notificationId: entry.row.id,
                        read: true,
                    },
                ];
                await updateNotificationForUnread(requestPayload, tenantId, partitionGlobalId);
                setloadingGrid(false);
                dataMgr?.update(data?.value ?? null);
            }
            if (entry.row.redirectionUrl) {
                if ((entry.event as any).ctrlKey) {
                    window.open(entry.row.redirectionUrl);
                } else {
                    window.open(entry.row.redirectionUrl, '_self');
                }
            }
        }, [ componentToSkip, data?.value, tenantId, partitionGlobalId, dataMgr ]);

    const setPublisherFilterData = useCallback((publisherFilter: IFilterDataType | undefined) => {
        if (publisherFilter !== undefined) {
            setSelectedPublisherFilter({
                label: publisherFilter?.label,
                value: publisherFilter?.value,
            });
            setGroupTopicTypes(publisherFilter.childs ?? []);
            setTopicTypes([]);
            setParamsArrayValueV2(publisherKey, publisherFilter?.value, [ topicKey, 'topicGroup' ]);
        } else {
            setSelectedPublisherFilter(allFilterOption);
            setGroupTopicTypes([]);
            setTopicTypes([]);
            setParamsArrayValueV2(publisherKey, undefined, [ topicKey, 'topicGroup' ]);
        }

        setSelectedTopicGroupFilter(allFilterOption);

        const filtersValueStore = removeFilters(filterQueryParamType, [ topicKey, publisherKey ]);

        if (publisherFilter !== undefined) {
            const filter = publisherFilter;
            if (filter.value !== '') {
                const filterData: IFilterQueryParamType = {
                    compValue: filter.value as string,
                    operand: publisherKey, // prop,
                    operator: 'eq',
                };
                filtersValueStore.push(filterData);
            }
        }
        setFilterQueryParamType(filtersValueStore);
        const filtersString = getFilterQuery(filtersValueStore);

        if (queryOptions.filter === filtersString) {
            return;
        }
        setPageIndex(DEFAULT_PAGE_INDEX);
        const changePageIndexOptions: ODataQueryOptions = {
            ...queryOptions,
            top: size,
            skip: DEFAULT_PAGE_INDEX * size,
            filter: filtersString,
        };
        setQueryOptions(changePageIndexOptions);

    }, [
        allFilterOption,
        filterQueryParamType,
        queryOptions,
        setParamsArrayValueV2,
        setSelectedPublisherFilter,
        setSelectedTopicGroupFilter,
        size,
    ]);

    const setTenantsFilterData = useCallback((tenantFilter: IFilterDataType | undefined) => {

        if (tenantFilter !== undefined) {
            setSelectedTenantFilter({
                label: tenantFilter?.label,
                value: tenantFilter?.value,
            });
            setParamsArrayValueV2(tenantKey, tenantFilter?.value);
        } else {
            setSelectedTenantFilter(allFilterOption);
            setParamsArrayValueV2(tenantKey, undefined);
        }

        const filtersValueStore = removeFilters(filterQueryParamType, [ tenantKey ]);

        if (tenantFilter?.value) {
            const filterData: IFilterQueryParamType = {
                compValue: tenantFilter.value as string,
                operand: tenantKey, // prop,
                operator: 'eq',
            };
            filtersValueStore.push(filterData);
        }
        setFilterQueryParamType(filtersValueStore);
        const filtersString = getFilterQuery(filtersValueStore);

        if (queryOptions.filter === filtersString) {
            return;
        }
        setPageIndex(DEFAULT_PAGE_INDEX);
        const changePageIndexOptions: ODataQueryOptions = {
            ...queryOptions,
            top: size,
            skip: DEFAULT_PAGE_INDEX * size,
            filter: filtersString,
        };
        setQueryOptions(changePageIndexOptions);

    }, [
        allFilterOption,
        filterQueryParamType,
        queryOptions,
        setParamsArrayValueV2,
        setSelectedTenantFilter,
        size,
    ]);

    const setSeverityFilterData = useCallback((severityFilter: IFilterDataType | undefined) => {
        if (severityFilter !== undefined) {
            setSelectedSeverityFilter({
                label: severityFilter?.label,
                value: severityFilter?.value,
            });
            setParamsArrayValueV2(categoryKey, severityFilter?.value);
        } else {
            setSelectedSeverityFilter(allFilterOption);
            setParamsArrayValueV2(categoryKey);
        }
        setPageIndex(DEFAULT_PAGE_INDEX);
        const filtersValueStore = [ ...filterQueryParamType ];
        const severityIndex = filtersValueStore.findIndex(x => x.operand === categoryKey);
        if (severityIndex !== -1) {
            filtersValueStore.splice(severityIndex, 1);
        }

        if (severityFilter !== undefined) {
            const filter = severityFilter;
            if (filter.value !== '') {
                const filterData: IFilterQueryParamType = {
                    compValue: `'${filter.value}'`,
                    operand: categoryKey, // prop,
                    operator: 'eq',
                };
                filtersValueStore.push(filterData);
            }
        }
        setFilterQueryParamType(filtersValueStore);
        const filtersString = getFilterQuery(filtersValueStore);

        if (queryOptions.filter === filtersString) {
            return;
        }
        const changePageIndexOptions: ODataQueryOptions = {
            ...queryOptions,
            top: size,
            skip: DEFAULT_PAGE_INDEX * size,
            filter: filtersString,
        };
        setQueryOptions(changePageIndexOptions);

    }, [
        allFilterOption,
        filterQueryParamType,
        queryOptions,
        setParamsArrayValueV2,
        setSelectedSeverityFilter,
        size,
    ]);

    const setTopicGroupFilterData = useCallback((topicGroupFilter: IFilterDataType | undefined) => {

        if (topicGroupFilter) {
            setSelectedTopicGroupFilter({
                label: topicGroupFilter.label,
                value: topicGroupFilter.value,
            });
            setSelectedTopicFilter(allFilterOption);
            setTopicTypes(topicGroupFilter.childs ?? []);
            setParamsArrayValueV2(topicKey);
            setParamsArrayValueV2('topicGroup', topicGroupFilter.value);
        } else {
            setSelectedTopicGroupFilter(allFilterOption);
            setSelectedTopicFilter(allFilterOption);
            setTopicTypes([]);
            setParamsArrayValueV2(topicKey);
            setParamsArrayValueV2('topicGroup');
        }

        const filtersValueStore = removeFilters(filterQueryParamType, [ topicKey ]);

        if (topicGroupFilter !== undefined) {
            const filter = topicGroupFilter;

            if (filter.childs?.length !== 0) {
                const filterData: IFilterQueryParamType = {
                    compValue: '',
                    operand: topicKey, // prop,
                    operator: 'or',
                    paramList: filter.childs,
                };
                filtersValueStore.push(filterData);
            }
        }
        setFilterQueryParamType(filtersValueStore);
        const filtersString = getFilterQuery(filtersValueStore);

        if (queryOptions.filter === filtersString) {
            return;
        }
        setPageIndex(DEFAULT_PAGE_INDEX);
        const changePageIndexOptions: ODataQueryOptions = {
            ...queryOptions,
            top: size,
            skip: DEFAULT_PAGE_INDEX * size,
            filter: filtersString,
        };
        setQueryOptions(changePageIndexOptions);

    }, [
        allFilterOption,
        filterQueryParamType,
        queryOptions,
        setParamsArrayValueV2,
        setSelectedTopicFilter,
        setSelectedTopicGroupFilter,
        size,
    ]);

    const setTopicFilterData = useCallback((topicFilter: IFilterDataType | undefined) => {

        if (topicFilter !== undefined) {
            setSelectedTopicFilter({
                label: topicFilter?.label,
                value: topicFilter?.value,
            });
            setParamsArrayValueV2(topicKey, topicFilter?.value);
        } else {
            setSelectedTopicFilter(allFilterOption);
            setParamsArrayValueV2(topicKey);
        }
        setPageIndex(DEFAULT_PAGE_INDEX);
        const filtersValueStore = removeFilters(filterQueryParamType, [ topicKey ]);

        if (topicFilter !== undefined) {
            const filter = topicFilter;
            if (filter.value !== '') {
                const filterData: IFilterQueryParamType = {
                    compValue: filter.value as string,
                    operand: topicKey, // prop,
                    operator: 'eq',
                };
                filtersValueStore.push(filterData);
            }
        } else {
            if (selectedFilters.topicGroup !== undefined) {
                const selectedTopicGroup = topicGroupTypes.find(x => x.value === selectedFilters.topicGroup?.value);
                const filterData: IFilterQueryParamType = {
                    compValue: '',
                    operand: topicKey, // prop,
                    operator: 'or',
                    paramList: selectedTopicGroup?.childs ?? [],
                };
                filtersValueStore.push(filterData);
            }
        }
        setFilterQueryParamType(filtersValueStore);
        const filtersString = getFilterQuery(filtersValueStore);

        if (queryOptions.filter === filtersString) {
            return;
        }

        const changePageIndexOptions: ODataQueryOptions = {
            ...queryOptions,
            top: size,
            skip: DEFAULT_PAGE_INDEX * size,
            filter: filtersString,
        };
        setQueryOptions(changePageIndexOptions);

    }, [
        allFilterOption,
        filterQueryParamType,
        queryOptions,
        selectedFilters.topicGroup,
        setParamsArrayValueV2,
        setSelectedTopicFilter,
        size,
        topicGroupTypes,
    ]);

    const setPublishedFilterData = useCallback((publishedFilter: IFilterDataType | undefined) => {

        const filtersValueStore = [ ...filterQueryParamType ];
        const itemIndex = filtersValueStore.findIndex(x => x.operand === publishedOnKey);
        if (itemIndex !== -1) {
            filtersValueStore.splice(itemIndex, 1);
        }
        if (publishedFilter !== undefined) {
            setSelectedPublishedOnFilter({
                label: publishedFilter?.label,
                value: publishedFilter?.value,
            });
            const filter = publishedFilter;
            if (filter.value !== '') {
                const showTillDate = getTimeTick(filter.value);
                const filterData: IFilterQueryParamType = {
                    compValue: showTillDate.toString(),
                    operand: publishedOnKey, // prop,
                    operator: 'ge',
                };
                filtersValueStore.push(filterData);
                setParamsArrayValueV2('gt', showTillDate?.toString());
            }

        } else {
            setParamsArrayValueV2('gt');
            setSelectedPublishedOnFilter(allFilterOption);
        }
        setPageIndex(DEFAULT_PAGE_INDEX);
        setFilterQueryParamType(filtersValueStore);
        const filtersString = getFilterQuery(filtersValueStore);

        const queryCurrOptions: ODataQueryOptions = {
            ...queryOptions,
            top: size,
            skip: DEFAULT_PAGE_INDEX * size,
            filter: filtersString,
        };
        setQueryOptions(queryCurrOptions);

    }, [
        allFilterOption,
        filterQueryParamType,
        queryOptions,
        setParamsArrayValueV2,
        setSelectedPublishedOnFilter,
        size,
    ]);

    const sortGridData = useCallback((sort: ISortModel<INotificationEntry>) => {
        if (!sort.field) {
            return ;
        }
        const oldOption = sortOpts.find(opt => opt.field === sort.field);
        const sortingOldOptString = oldOption ? `${oldOption.field} ${oldOption.direction}` : '';
        const noDirection = sort.direction === '' ? 'desc' : sort.direction;
        const sortingString = `${sort.field} ${noDirection}`;

        if (sortingOldOptString === sortingString) {
            return ;
        }
        const sortQueryOptions: ODataQueryOptions = {
            ...queryOptions,
            orderBy: [ sortingString ],
        };

        setSortOpts([ sort ]);
        setQueryOptions(sortQueryOptions);
        return true;
    }, [ queryOptions, sortOpts ]);

    const rowAction = useCallback(async (row: INotificationEntry, actionType: INotificationActionType, event: any) => {
        event.stopPropagation();
        event.preventDefault();
        switch (actionType) {
            case INotificationActionType.UnsubscribeTopic:
            {
                setloadingGrid(true);
                const topicStatePayload: IUpdateUserSubscriptionsRequestData = {
                    topicId: row.topicId,
                    isSubscribed: false,
                };
                try {
                    await updateUserNotificationTopicSubscription([ topicStatePayload ], tenantId);
                    setloadingGrid(false);
                    createNotification(translate({ id: 'CLIENT_NOTIFICATION_MUTED' }, { 0: row.topicName }),
                        notificationType.SUCCESS, {
                            horizontal: 'right',
                            vertical: 'top',
                        });
                } catch (e) {
                    showProfileError(e as Error);
                    setloadingGrid(false);
                }
                break;
            }
            case INotificationActionType.UnsubscribeTenant:
            {
                setloadingGrid(true);
                try {
                    await UnsubscribeTenantNotificationSubscription(tenantId);
                    setloadingGrid(false);
                    createNotification(translate({ id: 'CLIENT_NOTIFICATION_MUTED' }, { 0: tenantName }),
                        notificationType.SUCCESS, {
                            horizontal: 'right',
                            vertical: 'top',
                        });
                } catch (e) {
                    showProfileError(e as Error);
                    setloadingGrid(false);
                }
                break;
            }
            case INotificationActionType.Delete:
            {
                try {
                    setloadingGrid(true);
                    await deleteNotification(row.id, tenantId, partitionGlobalId);
                    createNotification(translate({ id: 'CLIENT_NOTIFICATION_CLEAR' }), notificationType.SUCCESS, {
                        horizontal: 'right',
                        vertical: 'top',
                    });
                    if (data !== undefined) {
                        data.value = data.value.filter(x => x.id !== row.id);
                        data['@odata.count'] = data['@odata.count'] - 1;
                        dataMgr?.update(data.value ?? null);
                    }
                    setloadingGrid(false);
                } catch (e) {
                    showProfileError(e as Error);
                }
                break;
            }
            case INotificationActionType.ToggleRead:
                {
                    setloadingGrid(true);
                    const requestPayload: INotificationReadRequest = {
                        notifications: [],
                        forceAllRead: false,
                    };
                    requestPayload.notifications = [
                        {
                            notificationId: row.id,
                            read: !row.isRead,
                        },
                    ];

                    await updateNotificationForUnread(requestPayload, tenantId, partitionGlobalId);
                    const originalRow = data?.value.find(x => x.id === row.id);
                    if (originalRow !== undefined) {
                        originalRow.isRead = !row.isRead;
                    }
                    notificationMutate();
                    setloadingGrid(false);
                }
                break;
        }

    }, [ createNotification, data, dataMgr, showProfileError, notificationMutate, partitionGlobalId, tenantId, tenantName, translate ]);

    const unreadMessageDecoration = useCallback((text: string, isRead: boolean, isMain: boolean, isSeverity: boolean) =>
        <NotificationCellComponent
            isMain={isMain}
            isRead={isRead}
            text={text}
            severity={isSeverity} />
    , []);

    useEffect(() => {
        let services: IFilterDataType[] = [];
        if (publishersData !== undefined) {
            const publishers = publishersData.publishers as INotificationPublisherDto[];
            services = getServiceList(publishers, allFilterOption.label);
            setPublisherTypes(services);
        }

        const tenantsFilter: IFilterDataType[] = tenantsData.map((tenant) => ({
            value: tenant.id,
            label: tenant.name,
        }));
        tenantsFilter.push(allFilterOption);
        setTenants(tenantsFilter);
    }, [ allFilterOption, allFilterOption.label, allFilterOption.value, publishersData, tenantsData ]);

    useEffect(() => {

        const params = {
            publisher: searchParams.get(publisherKey),
            topicGroup: searchParams.get('topicGroup'),
            topic: searchParams.get(topicKey),
            category: searchParams.get(categoryKey),
            greaterThan: searchParams.get('gt'),
            lessThan: searchParams.get('lt'),
            isRead: searchParams.get('isRead'),
            messageParam: searchParams.get('messageParam'),
            filterTenantId: searchParams.get('tenantId'),
        };

        setDefaultFilterQuery(params);
    }, [ searchParams, setDefaultFilterQuery ]);

    const customRenderMenu = (row: INotificationEntry) =>
        <NotificationMoreRowActionsComponent {...{
            notification: row,
            action: rowAction,
        }} />;

    return (
        <div style={{
            padding: '24px',
            width: '100%',
        }}>

            <ApDataGridWrapper<INotificationEntry>
                data={data?.value ?? []}
                collapseFiltersCount={6}
                selectable
                initialFilters={{
                    sort: [
                        {
                            field: 'type',
                            direction: 'asc',
                            title: translate({ id: 'CLIENT_EXTERNAL_APP_TYPE' }),
                        },
                    ],
                }}
                loading={notificationsLoading || loadingGrid}
                refreshable
                refresh={notificationMutate}
                rowClick={changeNotificationRead}
                dataCy="ap-data-grid-notification"
                onGridApi={(api) => {
                    setDataMgr(api.dataManager);
                    setSelectionMgr(api.selectionManager);
                    setFilterMgr(api.filterManager);
                }}
                sortChange={(current) => {
                    sortGridData(current);
                    return true;
                }}
            >
                <ApDataGridHeader<INotificationEntry>
                    search
                    searchValue={searchTermText}
                    searchTerm={(term: string) => {
                        updateGridData(DEFAULT_PAGE_INDEX, size, term);
                    }}
                >
                    <ApDataGridHeaderButton<INotificationEntry>
                        id='unreadFilter'
                        key='unreadFilter'
                        type='main'
                        buttonType='mat-flat-button'
                        color='primary'
                        text={translate({ id: 'CLIENT_NOTIFICATION_PAGE_UNREAD_ONLY' })}
                        label={translate({ id: 'CLIENT_NOTIFICATION_PAGE_UNREAD_ONLY' })}
                        icon='add'
                        dataCy='add-groups-button'
                        render={() =>
                            <FormControlLabel
                                control={<Checkbox
                                    className={classes.showOnlyUnread}
                                    checked={onlyUnread}
                                    onChange={(e) => handleHeaderCheckbox(e.target.checked)} />}
                                label={<UiText className={classes.showOnlyUnread}>
                                    {translate({ id: 'CLIENT_NOTIFICATION_PAGE_UNREAD_ONLY' })}
                                </UiText>} />}
                    />

                    <ApDataGridHeaderButton
                        id="deleteselected"
                        type="action"
                        render={() => <div style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Button
                                color="primary"
                                variant="text"
                                onClick={() => SelectionItemAction(INotificationActionType.Delete)}
                            >
                                {translate({ id: 'CLIENT_DELETE' })}
                            </Button>
                        </div>}
                    />

                    <ApDataGridHeaderButton
                        id="markasreadselected"
                        type="action"
                        render={() => <div style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Button
                                color="primary"
                                variant="text"
                                onClick={() => SelectionItemAction(INotificationActionType.ToggleRead)}
                            >
                                {translate({ id: 'CLIENT_NOTIFICATION_READ' })}
                            </Button>
                        </div>}
                    />

                    <ApDataGridHeaderButton
                        id="resetToDefaultfilters"
                        type="inline"
                        render={() => <div style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Button
                                color="primary"
                                variant="text"
                                onClick={ResetToDefault}
                            >
                                {translate({ id: 'CLIENT_NOTIFICATIONS_FILTER_RESET_DEFAULT' })}
                            </Button>
                        </div>}
                    />
                </ApDataGridHeader>

                <ApDataGridColumn<INotificationEntry>
                    property={tenantKey}
                    title={translate({ id: 'CLIENT_TENANT' })}
                    visible={false}
                >
                    <ApDataGridColumnDropdownFilter {...{
                        items: tenants?.map(t => ({
                            label: t.label,
                            value: t.value,
                        })) ?? [],
                        value: selectedFilters.tenant,
                        filterChange: (current) => {
                            const parentPublisher = tenants.find(x => x.value === current?.value);
                            setTenantsFilterData(parentPublisher);
                        },
                        showAllOption: true,
                    }} />
                </ApDataGridColumn>

                <ApDataGridColumn<INotificationEntry>
                    property={publisherKey}
                    title={translate({ id: 'CLIENT_NOTIFICATION_ENTRY_PUBLISHER_NAME' })}
                    visible={false}
                >
                    <ApDataGridColumnDropdownFilter {...{
                        items: publisherTypes?.map(t => ({
                            label: t.label,
                            value: t.value,
                        })) ?? [],
                        value: selectedFilters.publisher,
                        filterChange: (current) => {
                            const parentPublisher = publisherTypes.find(x => x.value === current?.value);
                            setPublisherFilterData(parentPublisher);
                        },
                        showAllOption: true,
                    }} />
                </ApDataGridColumn>

                <ApDataGridColumn<INotificationEntry>
                    property="topicgroupId"
                    title={translate({ id: 'CLIENT_NOTIFICATION_ENTRY_TOPIC_GROUP_NAME' })}
                    visible={false}
                >
                    <ApDataGridColumnDropdownFilter {...{
                        items: topicGroupTypes?.map(t => ({
                            label: t.label,
                            value: t.value,
                        })) ?? [],
                        visible: topicGroupTypes.length > 0,
                        value: selectedFilters.topicGroup,
                        filterChange: (current) => {
                            const parentTopicGroup = topicGroupTypes.find(x => x.value === current?.value);
                            setTopicGroupFilterData(parentTopicGroup);
                        },
                        showAllOption: true,
                    }} />
                </ApDataGridColumn>

                <ApDataGridColumn<INotificationEntry>
                    property={topicKey}
                    title={translate({ id: 'CLIENT_NOTIFICATION_ENTRY_TOPIC_NAME' })}
                    visible={false}
                >
                    <ApDataGridColumnDropdownFilter {...{
                        items: topicTypes?.map(t => ({
                            label: t.label,
                            value: t.value,
                        })) ?? [],
                        visible: topicTypes.length > 0,
                        value: selectedFilters.topic,
                        filterChange: (current) => {
                            const parentTopic = topicTypes.find(x => x.value === current?.value);
                            setTopicFilterData(parentTopic);
                        },
                        showAllOption: true,
                    }} />
                </ApDataGridColumn>

                <ApDataGridColumn<INotificationEntry>
                    property="category"
                    title={translate({ id: 'CLIENT_NOTIFICATION_ENTRY_SEVERITY' })}
                    width={15}
                    sortable
                    searchable
                    render={(entry) => unreadMessageDecoration(entry.category, false, true, true)}
                >
                    <ApDataGridColumnDropdownFilter {...{
                        items: severityTypesFilters?.map(t => ({
                            label: t.label,
                            value: t.value,
                        })) ?? [],
                        value: selectedFilters.severity,
                        filterChange: (current) => {
                            const parentSeverity = severityTypesFilters.find(x => x.value === current?.value);
                            setSeverityFilterData(parentSeverity);
                        },
                        showAllOption: true,
                    }} />

                </ApDataGridColumn>

                <ApDataGridColumn<INotificationEntry>
                    property="publisherName"
                    title={translate({ id: 'CLIENT_NOTIFICATION_ENTRY_PUBLISHER_NAME' })}
                    width={25}
                    searchable
                    render={(entry) => unreadMessageDecoration(entry.publisherName, entry.isRead, false, false)}
                />
                <ApDataGridColumn<INotificationEntry>
                    property="topicName"
                    title={translate({ id: 'CLIENT_NOTIFICATION_ENTRY_TOPIC_NAME' })}
                    width={30}
                    searchable
                    render={(entry) => unreadMessageDecoration(entry.topicName, entry.isRead, false, false)}
                />
                <ApDataGridColumn<INotificationEntry>
                    property="message"
                    title={translate({ id: 'CLIENT_NOTIFICATION_ENTRY_MESSAGE' })}
                    width={130}
                    searchable
                    render={(entry) => unreadMessageDecoration(entry.message, entry.isRead, true, false)}
                />
                <ApDataGridColumn<INotificationEntry>
                    property="publishedOn"
                    title={translate({ id: 'CLIENT_NOTIFICATION_ENTRY_TIMESTAMP' })}
                    width={30}
                    sortable
                    searchable
                    render={(entry) => unreadMessageDecoration(userReadableTime(parseInt(entry.publishedOn, 10)), entry.isRead, false, false)}
                >
                    <ApDataGridColumnDropdownFilter {...{
                        items: publishedOnTimeFilter?.map(t => ({
                            label: t.label,
                            value: t.value,
                        })) ?? [],
                        value: selectedFilters.publishedOn,
                        filterChange: (current) => {
                            const parentTopic = publishedOnTimeFilter.find(x => x.value === current?.value);
                            setPublishedFilterData(parentTopic);
                        },
                        showAllOption: true,
                    }} />
                </ApDataGridColumn>

                <ApDataGridRowActions>

                    <ApDataGridRowButton<INotificationEntry>
                        id='readbutton'
                        render={row => (
                            <ApTooltip content={row.isRead ? translate({ id: 'CLIENT_NOTIFICATION_UNREAD' }) :
                                translate({ id: 'CLIENT_NOTIFICATION_READ' })}>
                                <IconButton
                                    data-cy='ui-grid-read-unread-button'
                                    color={`${row.isRead ? 'inherit' : 'primary'}`}
                                    onClick={(event) => rowAction(row, INotificationActionType.ToggleRead, event)}>
                                    <CircleIcon className={classes.circleiconClass} />
                                </IconButton>
                            </ApTooltip>
                        )}
                        buttonType='mat-flat-button'
                        color='primary'
                    />
                    <ApDataGridRowButton<INotificationEntry>
                        id='contextmenu'
                        render={customRenderMenu}
                    />

                </ApDataGridRowActions>

                <ApDataGridFooter
                    length={data?.['@odata.count'] ?? 0}
                    pageIndex={index}
                    pageSize={size}
                    pageChange={({
                        pageIndex, pageSize,
                    }) => {
                        if (index !== pageIndex || size !== pageSize) {
                            updateGridData(pageIndex, pageSize, searchTermText);
                        }
                    }}
                    pageSizes={[ 5, 10, 25, 50 ]}
                />

            </ApDataGridWrapper>
        </div>
    );
};

export default NotificationPageComponentV2;

import type {
    TMOData,
    TMProject,
} from '@experiences/interfaces';
import { get } from '@experiences/util';

export function getTMProjects(accessToken: string, tenantName: string, top = 20) {
    const url = `/${tenantName}/testmanager_/api/v2/projects?orderby=updated%20desc&top=${top}`;

    return get<TMOData<TMProject>>(url, {
        accessToken,
        stripPortalPath: true,
    });
}

export function getTMRelevantProjects(accessToken: string, tenantName: string, top = 20) {
    const url = `/${tenantName}/testmanager_/api/v2/getprojectsbyuserrelevance?favourite=true&recent=true&top=${top}`;

    return get<TMOData<TMProject>>(url, {
        accessToken,
        stripPortalPath: true,
    });
}

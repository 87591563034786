import type {
    IQuotaDefinition,
    QuotaDefinitionRequest,
} from '../../../common/interfaces/licenses';
import {
    get,
    put,
} from '../../utility/Requests';

export async function getQuotaDefinitionsByScope({
    serviceScopeId, quotaScopeId, collection,
}: { serviceScopeId: string; quotaScopeId: string; collection: string }) {
    return await get<IQuotaDefinition[]>(`/api/license/management/quota/${serviceScopeId}/scope/${quotaScopeId}/${collection}`, { shouldEnumerate: false });
}

export async function setQuotaCollectionByScope({
    serviceScopeId, quotaScopeId, collection, quota,
}: { serviceScopeId: string; quotaScopeId: string; collection: string; quota: QuotaDefinitionRequest[] }) {
    return await put(`/api/license/management/quota/${serviceScopeId}/scope/${quotaScopeId}/${collection}`, { body: quota });
}

export enum CheckAccessEvent {
    NavigationClick = 'CheckAccess.NavigationClick',
    TabClick = 'CheckAccess.TabClick',
    PermissionTabs = 'CheckAccess.PermissionsTabs',
    PermissionsDropdownClick = 'CheckAccess.PermissionsDropdownClick',
    SearchClear = 'CheckAccess.SearchClear',
    SearchQuery = 'CheckAccess.SearchQuery',
    RoleAssignmentLinks = 'CheckAccess.RoleAssignmentLinks',
    CheckAccess = 'CheckAccess.ClickedCheckAccess',
    CheckAccessUpgrade = 'CheckAccess.ClickedCheckAccessUpgrade',
    CheckAccessContact = 'CheckAccess.ClickedCheckAccessContact',
}

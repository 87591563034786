import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import type {
    IBlob,
    IFile,
    IRequestProps,
} from '@experiences/interfaces';
import {
    axiosDelete as axiosDeleteUtil,
    axiosInstance,
    get as getUtil,
    getBlob as getBlobUtil,
    getFile as getFileUtil,
    patch as patchUtil,
    post as postUtil,
    put as putUtil,
} from '@experiences/util';

import { getToken } from '../../auth/utils/Token';

const EnableAzureRefreshTokenExpiryInterceptor = getFeatureFlagValue(Features.EnableAzureRefreshTokenExpiryInterceptor.name);

axiosInstance.interceptors.response.use(undefined, (error) => {
    if (EnableAzureRefreshTokenExpiryInterceptor && error.status === 440) {
        window.location.assign('/portal_/autherror?errorCode=440');
    }

    return Promise.reject(error);
});

export async function get<T>(url: string, requestProps?: IRequestProps): Promise<T> {
    const accessToken = requestProps?.accessToken ?? await getToken() as string;

    return getUtil<T>(url, {
        ...requestProps,
        accessToken,
    });
}

export async function getBlob(url: string, requestProps?: IRequestProps): Promise<IBlob> {
    const accessToken = requestProps?.accessToken ?? await getToken() as string;

    return getBlobUtil(url, {
        ...requestProps,
        accessToken,
    });
}

export async function getFile(url: string, requestProps?: IRequestProps): Promise<IFile> {
    const accessToken = requestProps?.accessToken ?? await getToken() as string;

    return getFileUtil(url, {
        ...requestProps,
        accessToken,
    });
}

export async function post<T>(url: string, requestProps?: IRequestProps) {
    const accessToken = requestProps?.accessToken ?? await getToken() as string;

    return postUtil<T>(url, {
        ...requestProps,
        accessToken,
    });
}

export async function put<T>(url: string, requestProps?: IRequestProps) {
    const accessToken = requestProps?.accessToken ?? await getToken() as string;

    return putUtil<T>(url, {
        ...requestProps,
        accessToken,
    });
}

export async function patch<T>(url: string, requestProps?: IRequestProps) {
    const accessToken = requestProps?.accessToken ?? await getToken() as string;

    return patchUtil<T>(url, {
        ...requestProps,
        accessToken,
    });
}

export async function axiosDelete(url: string, requestProps?: IRequestProps) {
    const accessToken = requestProps?.accessToken ?? await getToken() as string;

    return axiosDeleteUtil(url, {
        ...requestProps,
        accessToken,
    });
}

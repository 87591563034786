import {
    type Entitlement,
    Entitlements,
    entitlementsToLegacyCodes,
} from '@experiences/constants';
import type { IsEntitledMultiple } from '@experiences/interfaces';
import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    entitlementsUrl,
    isEntitledMultiple,
} from '../../services/licensing/EntitlementsService';
import { accountGlobalId } from '../../store/selectors';

type EntitlementsState = Record<Entitlement, boolean>;

const REVALIDATE_INTERVAL = 4 * 60 * 60 * 1000; // 4 hours

interface IEntitlementsContext {
    entitlements: IsEntitledMultiple | undefined;
    isLoading: boolean;
    isValidating: boolean;
    error?: Error;
    hasEntitlement: (entitlement: Entitlement) => boolean;
    refreshEntitlements: () => Promise<void>;
}

const EntitlementsContext = createContext<IEntitlementsContext>({
    entitlements: undefined,
    isLoading: false,
    isValidating: false,
    hasEntitlement: () => false,
    refreshEntitlements: async () => {},
});

export const useEntitlements = () => useContext(EntitlementsContext);

const getAllEntitlements = () => {
    const entitlementsList = Object.values(Entitlements);
    const legacyCodeEntitlements = Object.keys(entitlementsToLegacyCodes)
        .filter(key => !entitlementsList.includes(key as Entitlement));

    return [ ...entitlementsList, ...legacyCodeEntitlements ];
};

export const EntitlementsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const organizationId = useSelector(accountGlobalId);
    const {
        data: entitlementsResponse,
        error,
        isLoading,
        isValidating,
        mutate,
    } = useSWR<IsEntitledMultiple>(
        organizationId ? {
            url: entitlementsUrl,
            entitlements: getAllEntitlements(),
        } : null,
        isEntitledMultiple,
        { refreshInterval: REVALIDATE_INTERVAL },
    );

    // Initialize all entitlements as false
    const initialEntitlements = useMemo<EntitlementsState>(() => Object.values(Entitlements).reduce((acc, entitlement) => ({
        ...acc,
        [entitlement]: false,
    }), {} as EntitlementsState), []);

    // Update entitlements state when data is fetched
    const entitlementsState = useMemo(() => {
        if (!entitlementsResponse?.isEntitled) {
            return initialEntitlements;
        }

        return Object.values(Entitlements).reduce((acc, entitlement) => ({
            ...acc,
            [entitlement]: entitlementsResponse.isEntitled[entitlement] ?? false,
        }), {} as EntitlementsState);
    }, [ entitlementsResponse, initialEntitlements ]);

    const hasEntitlement = useCallback(
        (entitlement: Entitlement): boolean => entitlementsState[entitlement] ?? false,
        [ entitlementsState ]
    );

    const refreshEntitlements = useCallback(async () => {
        await mutate();
    }, [ mutate ]);

    const value = useMemo(() => ({
        entitlements: organizationId ? entitlementsResponse : undefined,
        hasEntitlement,
        isLoading: organizationId ? isLoading : false,
        isValidating: organizationId ? isValidating : false,
        error: organizationId ? error : undefined,
        refreshEntitlements,
    }), [
        organizationId,
        entitlementsResponse,
        hasEntitlement,
        isLoading,
        isValidating,
        error,
        refreshEntitlements,
    ]);

    return (
        <EntitlementsContext.Provider value={value}>
            {children}
        </EntitlementsContext.Provider>
    );
};

export enum DirectoryEntityType {
    user = 0,
    group = 1,
}

export type ResolveDirectoryEntityType =
  | 'DirectoryUser'
  | 'DirectoryGroup'
  | 'LocalUser'
  | 'LocalGroup'
  | 'RobotAccount';

export enum DirectoryEntryType {
    DirectoryUser = 'DirectoryUser',
    DirectoryGroup = 'DirectoryGroup',
    LocalUser = 'LocalUser',
    LocalGroup = 'LocalGroup',
    RobotAccount = 'RobotAccount',
    DirectoryApplication = 'DirectoryApplication',
}

export interface IDirectoryEntry {
    domain?: string;
    source: string;
    identifier: string;
    identityName: string;
    displayName: string;
    email?: string;
    type: DirectoryEntityType;
    objectType?: ResolveDirectoryEntityType;
    chipType?: string;
    optionId?: string;
}

export interface IResolveDirectoryEntry {
    displayName: string;
    email: string;
    identifier: string;
    name: string;
    objectType: ResolveDirectoryEntityType;
    source: string;
}

import { REQUEST_BASE_ROUTE } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    useAuthContext,
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import useSWR from 'swr';

import { useEcommerceTelemetry } from '../helpers/EcommerceHelpers';
import { BuyProCheckout } from '../helpers/EcommerceRoutes';
import { useEcommerce } from '../helpers/useEcommerce';
import type {
    IBusinessInfoPayload,
    IEcommerceCheckoutFormState,
    IPackage,
    IProduct,
    IProductPrice,
    IProductPriceOptions,
    ISkuPackage,
} from '../interfaces/ecommerce';
import {
    billingUrl,
    getExistingUserMarketoData,
} from '../services/BillingService';

const useEcommercePlanSummaryViewModel = (
    currentAccountName: string,
    products: IProduct[],
    isFormValid: boolean,
    planPackage: IPackage | undefined,
    selectedCurrency: string,
    price?: number,
    productsPricesInAllCurrencies?: IProductPriceOptions[],
    checkoutFormState?: IEcommerceCheckoutFormState,
) => {
    const { token } = useAuthContext();
    const getRoute = useRouteResolver();
    const navigate = useNavigateWithParams();
    const logEcommerceEvent = useEcommerceTelemetry(true);
    const enableEcommercePricingV2 = useFeatureFlagValue(Features.EnableEcommercePricingV2.name);

    const [ existingMarketoData, setExistingMarketoData ] = useState<IBusinessInfoPayload | undefined>();
    const { data: newMarketoData } = useSWR<IBusinessInfoPayload>(
        {
            accountName: currentAccountName,
            url: `${billingUrl}/marketo`,
            accessToken: token,
        },
        getExistingUserMarketoData,
        {
            revalidateOnFocus: false,
            shouldRetryOnError: false,
        },
    );
    const {
        currentSkuPackageDetails, setSelectedPlanType, arePricesLoading: packageLoading,
    } = useEcommerce(undefined, selectedCurrency);

    useEffect(() => {
        setExistingMarketoData(newMarketoData);
    }, [ setExistingMarketoData, newMarketoData ]);

    const emptyData = useMemo(() => products.filter(p => p.quantity > 0).length === 0, [ products.filter(p => p.quantity > 0), products ]);
    const areQuantitiesRequirementsMet = useMemo(() => emptyData ||
            products.filter(p => ((p.minQuantity && p.quantity < p.minQuantity)
                || (p.maxQuantity && p.quantity > p.maxQuantity))).length === 0,
    [ products, JSON.stringify(products), emptyData ]);

    const productPrices = useMemo(() => {
        if (productsPricesInAllCurrencies) {
            return productsPricesInAllCurrencies.map(p => ({
                code: p.code,
                planType: p.planType,
                price: p.prices[selectedCurrency],
            } as IProductPrice));
        }
    }, [ productsPricesInAllCurrencies, selectedCurrency ]);

    const getUnitPrice = useCallback((product: { code: string }, allProductPrices: IProductPriceOptions[]) =>
        allProductPrices.find(p => (p.code === product.code &&
                p.planType === currentSkuPackageDetails.planType))?.prices[selectedCurrency] ?? 0,
    [ currentSkuPackageDetails.planType, selectedCurrency ]);

    const getProductPriceConsideringQuantity = useCallback((product: any, allProductPrices: IProductPriceOptions[]) => {
        const unitPrice = getUnitPrice(product, allProductPrices);
        return (product.quantity * unitPrice);
    }, [ getUnitPrice ]);

    const hasChangedProducts = JSON.stringify(products);
    const subscriptionPrice = useMemo(() => {
        if (price) {
            return price;
        }
        let priceTotal = 0;
        if (productsPricesInAllCurrencies && hasChangedProducts) {
            products.forEach(product => {
                priceTotal += getProductPriceConsideringQuantity(product, productsPricesInAllCurrencies);
            });
        }

        return priceTotal;
    }, [ price, products, hasChangedProducts, productsPricesInAllCurrencies, getProductPriceConsideringQuantity ]);

    const buySkuPackage = useCallback((packageType: string) => {
        const skuPackage = {
            currency: selectedCurrency,
            products: planPackage?.productQuantities,
            type: packageType,
            planType: currentSkuPackageDetails.planType,
        } as ISkuPackage;

        logEcommerceEvent('Licenses.SelectPlan', {
            SelectedPlan: packageType,
            SelectedProducts: skuPackage.products,
        });

        navigate(getRoute(BuyProCheckout),
            {
                state: {
                    skuPackage,
                    selectedCurrency,
                    productsPricesInAllCurrencies,
                    existingMarketoData,
                },
            });
    }, [
        selectedCurrency,
        currentSkuPackageDetails.planType,
        planPackage,
        productsPricesInAllCurrencies,
        navigate,
        getRoute,
        existingMarketoData,
        logEcommerceEvent,
    ]);

    const continueToDirectBuy = useCallback(() => {
        const quantities = JSON.stringify(planPackage?.productQuantities
            .filter(product => product.quantity > 0)
            .reduce((accumulator: { [key: string]: number }, product) => {
                accumulator[product.code] = product.quantity;
                return accumulator;
            }, {}));
        const params = new URLSearchParams({
            db_product_quantities: encodeURI(quantities),
            db_commitment_type: currentSkuPackageDetails.planType,
            db_currency: selectedCurrency,
            ecommerceRedirect: 'true',
        });

        logEcommerceEvent('DirectBuy.SelectPlan', {
            SelectedPlan: planPackage?.type,
            SelectedProducts: planPackage?.productQuantities,
        });

        const newRoute = `${getRoute(`${REQUEST_BASE_ROUTE}/register`)}?${params.toString()}`;
        navigate(newRoute);
    }, [ planPackage?.productQuantities, currentSkuPackageDetails.planType, selectedCurrency, getRoute, navigate ]);

    const isPayButtonDisabled = useMemo(() => {
        if (!isFormValid || !checkoutFormState) {
            return true;
        }

        return (!checkoutFormState.paymentElementFormCompleted || !checkoutFormState.termsAndConditionsAccepted);

    }, [ isFormValid, checkoutFormState ]);

    const isPayButtonLoading = useMemo(() => {
        if (packageLoading === undefined || !checkoutFormState) {
            return true;
        }

        return checkoutFormState.loading || packageLoading;
    }, [ checkoutFormState, packageLoading ]);

    const submitCheckoutFormRef = useCallback(() => {
        checkoutFormState?.checkoutFormRef.dispatchEvent(
            new Event('submit', {
                bubbles: true,
                cancelable: true,
            })
        );
    }, [ checkoutFormState ]);

    return {
        currentSkuPackageDetails,
        setSelectedPlanType,
        emptyData,
        areQuantitiesRequirementsMet,
        productPrices,
        getUnitPrice,
        getProductPriceConsideringQuantity,
        subscriptionPrice,
        isPayButtonDisabled,
        isPayButtonLoading,
        buySkuPackage,
        submitCheckoutFormRef,
        continueToDirectBuy,
        enableEcommercePricingV2,
    };
};

export default useEcommercePlanSummaryViewModel;

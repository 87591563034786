import {
    SpacingToken,
    UiStack,
} from '@experiences/ui-common';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useMemo } from 'react';

export interface UiPageContainerProps {
    children?: React.ReactNode;
    loading?: boolean;
    position?: 'left' | 'farleft' | 'center';
    offsetWidth?: string;
    maxWidth?: string;
    banner?: React.ReactNode;
    breadcrumb?: React.ReactNode;
    header?: React.ReactNode;
    primaryActions?: React.ReactNode;
    tenantShellHidden?: boolean;
    disableGutter?: Array<'left' | 'right' | 'top' | 'bottom' | 'all'>;
    enableBackground?: boolean;
    scrollable?: boolean;
}

interface IUiPageContainerThemeProps {
    maxWidth: string;
    offsetWidth: string;
    marginLeft: boolean;
    scrollable: boolean;
}

const useStyles = makeStyles<Theme, IUiPageContainerThemeProps>(theme =>
    createStyles({
        root: ({
            maxWidth, marginLeft,
        }) => ({
            display: 'flex',
            flexDirection: 'column',
            flexBasis: '900px',
            flexGrow: 1,
            flexShrink: 1,
            maxWidth,
            marginLeft: marginLeft ? 'auto' : 'none',
            marginRight: 'auto',
            minWidth: 0,
            backgroundColor: theme.palette.semantic.colorBackground,
        }),
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
        },
        background: { backgroundColor: theme.palette.semantic.colorBackgroundSecondary },
        containerWithShell: ({ offsetWidth = 0 }) => ({
            width: `calc(100vw - 379px - ${offsetWidth})`, // 68px is left nav + 311px is tenant selector
            '@media all and (max-width: 1150px)': { width: 'calc(100vw - 242px)' }, // 68px is left nav + 174px is tenant selector
        }),
        headerContainer: {
            display: 'flex',
            flexDirection: 'column',
            padding: '12px 10px',
        },
        headerBar: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
        },
        headerBreadcrumbBox: { flex: 1 },
        headerComponent: {
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: '10px',
        },
        mainContent: ({ scrollable }) => ({
            display: 'flex',
            flexDirection: 'column',
            overflow: scrollable ? 'auto' : 'none',
            flex: 1,
            scrollbarColor: `unset ${theme.palette.semantic.colorForegroundDeEmp}`,
            scrollbarWidth: 'thin',

            '&::-webkit-scrollbar': { width: '0.5em' },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                backgroundColor: 'rgba(0,0,0,0.04)',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.semantic.colorBackgroundGray,
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:vertical:hover': { backgroundColor: theme.palette.semantic.colorForegroundDisable },
        }),
        loadingSpinner: { margin: 'auto' },
        banner: {
            width: '100%',
            marginBottom: '12px',
        },
    }),
);

const UiPageContainer: React.FC<UiPageContainerProps> = ({
    children,
    loading = false,
    position = 'center',
    maxWidth = '1600px',
    offsetWidth = '0px',
    banner,
    breadcrumb,
    header,
    primaryActions,
    tenantShellHidden = false,
    disableGutter,
    enableBackground = false,
    scrollable = true,
}) => {
    const classes = useStyles({
        maxWidth,
        marginLeft: position !== 'farleft',
        offsetWidth,
        scrollable,
    });

    const isCenter = useMemo(() => position === 'center', [ position ]);

    const marginValues = useMemo(() => {
        let margin = '';

        if (disableGutter?.includes('all')) {
            return '0px';
        }

        margin += disableGutter?.includes('top') ? '0px ' : '20px ';
        margin += disableGutter?.includes('right') ? '0px ' : '20px ';
        margin += disableGutter?.includes('bottom') ? '0px ' : '20px ';
        margin += disableGutter?.includes('left') ? '0px' : '20px';

        return margin;
    }, [ disableGutter ]);

    return (
        <Box className={clsx({
            [classes.container]: true,
            [classes.containerWithShell]: !tenantShellHidden && !window.env.tenantShellHidden,
            [classes.background]: enableBackground,
        })}>
            <Box
                className={classes.root}
                data-cy='ui-page-container'>
                {/* TODO: revisit this when all pages have a header, and set the role correctly. */}
                <UiStack
                    role="heading"
                    aria-level={1}
                    direction="column"
                    pt={SpacingToken.S}
                    pl={SpacingToken.M}
                    pr={SpacingToken.M}
                    w="100%">
                    <UiStack
                        direction="row"
                        align="center"
                        justify="between"
                        style={{
                            whiteSpace: 'nowrap',
                            minHeight: '50px',
                        }}>
                        <UiStack
                            direction="row"
                            align="center"
                            gap={SpacingToken.S}
                            style={{ flex: 1 }}>
                            {breadcrumb}
                        </UiStack>
                        {primaryActions}
                    </UiStack>
                    {!!header && <div
                        role="heading"
                        aria-level={1}>
                        {header}
                    </div>}
                </UiStack>
                {!!banner && <Box className={classes.banner}>
                    {banner}
                </Box>}
                {loading
                    ? <CircularProgress
                        className={classes.loadingSpinner}
                        data-cy='ui-page-container-loading' />
                    : <Box
                        className={classes.mainContent}
                        style={{
                            alignItems: isCenter ? 'center' : 'normal',
                            padding: marginValues,
                            // fixes issue with grid where it's showing content within the padded area
                            paddingTop: '0',
                            marginTop: marginValues,
                        }}
                        data-cy='ui-page-container-content'>
                        {children}
                    </Box>}
            </Box>
        </Box>
    );
};

export default UiPageContainer;

import { useQueryState } from '@experiences/util';
import type { PropsWithChildren } from 'react';
import React, {
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';
import useSWR from 'swr';

import { getFriendlyName } from '../../../common/constants/ServicesMapping';
import { useTenantIdContext } from '../../../common/providers/TenantIdProvider';
import { getEffectiveAccess } from '../../../services/centralizedaccess/CentralizedAccessService';
import type { Folder } from '../../../services/orchestrator/OrchFolderService';

export interface ICheckAccessPrincipal {
    identifier: string;
    displayName: string;
    email?: string;
}

interface ICheckAccessContext {
    principal?: ICheckAccessPrincipal;
    setPrincipal: React.Dispatch<React.SetStateAction<ICheckAccessPrincipal | undefined>>;
    roleId?: string;
    setRoleId: React.Dispatch<React.SetStateAction<string | undefined>>;
    selectedScope?: string;
    setSelectedScope: React.Dispatch<React.SetStateAction<string | undefined>>;
    scopedServiceName?: string;
    scopedServiceDisplayName?: string;
    selectedFolder?: Folder;
    setSelectedFolder: React.Dispatch<React.SetStateAction<Folder | undefined>>;
}

const CheckAccessContext = createContext<ICheckAccessContext>({} as ICheckAccessContext);

export const useCheckAccessContext = () => useContext(CheckAccessContext);

export const CheckAccessProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const {
        services, tenantId,
    } = useTenantIdContext();

    // Selected principal
    const [ principal, setPrincipal ] = useQueryState<ICheckAccessPrincipal | undefined>('principal', undefined, undefined, true);

    // Selected Treeview state
    const [ selectedScope, setSelectedScope ] = useQueryState<string | undefined>('scope', undefined, undefined, true);

    // Selected folder
    const [ selectedFolder, setSelectedFolder ] = useState<Folder | undefined>();

    // Selected role id
    const [ roleId, setRoleId ] = useQueryState<string | undefined>('roleId', undefined, undefined, true);

    // Scoped ServiceName for permissions call, undefined when tenant is selected
    const [ scopedServiceName, setScopedServiceName ] = useState<string>();
    const [ scopedServiceDisplayName, setScopedServiceDisplayName ] = useState<string>();

    // This call is used to get the effective services at tenant for the selected principal
    // The actual effective permissions here are ignored and called somewhere else with proper pagination
    const { data } = useSWR(
        principal && tenantId ? {
            url: `/pap_/api/geteffectiveaccess`,
            pagination: {
                top: 10,
                skip: 0,
            },
            payload: {
                SecurityPrincipalId: principal.identifier,
                ScopeIdentifier: {
                    ScopeType: 'Tenant',
                    Value: {
                        Id: tenantId,
                        ParentId: tenantId,
                    },
                },
            },
        } : null,
        getEffectiveAccess,
    );

    // Set default selected scope to tenantId once the principal is set
    useEffect(() => {
        if (principal) {
            setSelectedScope(prev => prev ?? tenantId);
        }
    }, [ setSelectedScope, tenantId, principal ]);

    useEffect(() => {
        if (!selectedScope) {
            return;
        }

        const matchedService = services?.find(service => service.id === selectedScope);
        const matchedServiceFromData = matchedService?.serviceType
            ? data?.grantedServicesMetadata?.find(service => service.serviceType === matchedService.serviceType)
            : undefined;

        const serviceType = matchedService?.serviceType;
        setScopedServiceName(matchedServiceFromData?.serviceName ?? (serviceType ? getFriendlyName(serviceType).replace(' ', '') : undefined));
        setScopedServiceDisplayName(matchedServiceFromData?.serviceDisplayName ?? (serviceType ? getFriendlyName(serviceType) : undefined));
    }, [ services, selectedScope, data?.grantedServicesMetadata ]);

    return <CheckAccessContext.Provider value={{
        principal,
        setPrincipal,
        roleId,
        setRoleId,
        selectedScope,
        setSelectedScope,
        scopedServiceName,
        scopedServiceDisplayName,
        selectedFolder,
        setSelectedFolder,
    }}>
        {children}
    </CheckAccessContext.Provider>;
};

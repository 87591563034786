import { GlobalStyles } from '@experiences/theme';
import {
    UiSuspensefulOutlet,
    UiText,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { FontVariantToken } from '@uipath/apollo-core';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    BuyProIndividualProducts,
    DirectBuyIndividualProducts,
} from '../helpers/EcommerceRoutes';
import { accountLogicalName } from '../helpers/EcommerceSelectors';
import { EcommerceProvider } from '../helpers/useEcommerce';
import { EcommerceBusinessFeaturesForCloud } from '../subcomponents/EcommerceBusinessFeaturesForCloud';
import { EcommerceContactSalesLinkComponent } from '../subcomponents/EcommerceContactSalesLinkComponent';
import { EcommerceCurrencyDropdown } from '../subcomponents/EcommerceCurrencyDropdown';
import { EcommercePlanSummary } from '../subcomponents/EcommercePlanSummary';
import useEcommercePresetsViewModel from './useEcommercePresetsViewModel';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        presetsContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            maxWidth: '100%',
        },
        presetsColumns: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            columnGap: '20%',
        },
        mainSection: {
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            maxWidth: '1080px',
        },
        summarySection: { alignSelf: 'stretch' },
        summaryWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '50%',
            borderRadius: '16px',
        },
        summaryComponent: {
            display: 'flex',
            width: '387px',
            height: '481px',
            backgroundColor: theme.palette.semantic.colorChipInfoBackground,
            borderRadius: '16px',
            marginTop: '60px',
        },
        radioCard: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            justifyContent: 'center',
            boxSizing: 'border-box',
            paddingTop: '20px',
            paddingBottom: '24px',
            minWidth: '571px',
            marginBottom: '16px',
            marginLeft: '20px',
            border: '1px solid',
            borderColor: theme.palette.semantic.colorHover,
            borderRadius: '8px',
            fontFamily: 'Noto Sans',
            '&:hover': {
                borderColor: theme.palette.semantic.colorForegroundLink,
                backgroundColor: theme.palette.semantic.colorHover,
            },
        },
        selectedRadioCard: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            justifyContent: 'center',
            boxSizing: 'border-box',
            paddingTop: '20px',
            paddingBottom: '24px',
            minWidth: '571px',
            marginBottom: '16px',
            marginLeft: '20px',
            border: '1px solid',
            borderColor: theme.palette.semantic.colorForegroundLink,
            borderRadius: '8px',
            fontFamily: 'Noto Sans',
            '&:hover': {
                borderColor: theme.palette.semantic.colorForegroundLink,
                backgroundColor: theme.palette.semantic.colorHover,
            },
        },
        productDetailsContainer: {
            display: 'flex',
            marginLeft: '60px',
            flexDirection: 'column',
        },
        productDetails: { display: 'flex' },
        packageDetails: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: '30px',
            marginRight: '30px',
            '& .MuiRadio-root': {
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        packageName: {
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
        },
        packagePrice: {
            fontSize: '20px',
            lineHeight: '24px',
            fontWeight: '600',
            paddingRight: '4px',
            color: theme.palette.semantic.colorForeground,
        },
        planType: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '400',
            color: theme.palette.semantic.colorForegroundDeEmp,
            whiteSpace: 'pre-wrap',
        },
        learnMoreLink: { textDecoration: 'none' },
        learnMore: {
            size: '14px',
            lineHeight: '20px',
            fontWeight: '400',
            marginLeft: '60px',
            textDecoration: 'none',
            color: theme.palette.semantic.colorForegroundLink,
        },
        packageInnerDetails: {
            display: 'flex',
            alignItems: 'flex-end',
        },
        presetsTitle: {
            fontWeight: '600',
            fontSize: '24px',
            lineHeight: '32px',
            width: '100%',
            color: theme.palette.semantic.colorForeground,
        },
        presetsSubTitle: {
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            marginTop: '35px',
            marginBottom: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        presetsDescription: {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
        },
        dividerSection: {
            minWidth: '571px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '36px',
            marginLeft: '20px',
        },
        divider: { width: '47%' },
        headerDivider: { width: '100%' },
        dividerText: {
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
        },
        individualLicensesButton: {
            marginTop: '32px',
            minWidth: '571px',
            height: '68px',
            marginLeft: '20px',
            borderRadius: '3px',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForegroundLink,
            textAlign: 'center',
            '&:hover': { backgroundColor: theme.palette.semantic.colorPrimaryHover },
        },
        businessFeaturesSection: {
            marginLeft: '20px',
            marginTop: '40px',
        },
        titleRow: {
            width: 'auto',
            marginLeft: '20px',
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        salesLink: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '24px',
        },
        headerRow: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: '48px',
            paddingLeft: '40px',
            width: '100%',
        },
        headerContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            maxWidth: '100%',
        },
        buyEnterpriseText: { paddingRight: '4px' },
        productDetailsQuantity: { paddingRight: '1ch' },
        productDetailsText: { whiteSpace: 'pre-wrap' },
        pickerRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginLeft: '20px',
            minWidth: '571px',
        },
        picker: { marginBottom: '8px' },
        loading: {
            position: 'absolute',
            top: '50%',
            left: '50%',
        },
    }),
}));

export const EcommercePresetsComponent: React.FC<{ isDirectBuyFlow: boolean }> = ({ isDirectBuyFlow }) => {
    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const currentAccountName = useSelector(accountLogicalName);
    const { formatMessage: translate } = useIntl();

    const {
        packagesData,
        loading,
        selectedPackage,
        currentCurrency,
        currentSkuPackageDetails,
        productsPricesInAllCurrencies,
        selectedPackageType,
        getPrice,
        skuCodeToOfferType,
        planTypeText,
        isMonthlyPlanType,
        onSelectOption,
        price,
        setCurrentCurrency,
        showDialogCallback,
        presetsOfferSubtitle,
        enableEcommercePricingV2,
    } = useEcommercePresetsViewModel(currentAccountName, isDirectBuyFlow);

    return (
        loading ? (
            <div className={classes.loading}>
                <CircularProgress />
            </div>
        ) :
            (
                <div className={classes.presetsContent}>
                    {isDirectBuyFlow &&
                    <div className={classes.headerContent}>
                        <div className={classes.headerRow}>
                            <img
                                src="https://staticresourcescp.blob.core.windows.net/auth0-spa/UiPath-full-logo.svg"
                                alt="UiPath"
                                className="uipathLogoImage" />
                        </div>
                        <Divider className={classes.headerDivider} />
                    </div>}
                    <div className={classes.presetsColumns}>
                        <div className={classes.mainSection}>
                            <div className={classes.titleRow}>
                                <div>
                                    <UiText
                                        className={classes.presetsTitle}
                                        variant={FontVariantToken.fontSizeH1}>
                                        {translate({ id: 'CLIENT_ECOMMERCE_BUY_AUTOMATION_CLOUD_PRO' })}
                                    </UiText>
                                    {isMonthlyPlanType &&
                                        <UiText>
                                            {translate({ id: 'CLIENT_ECOMMERCE_PAY_MONTHLY_CANCEL_ANYTIME' })}
                                        </UiText>}
                                </div>
                            </div>

                            {enableEcommercePricingV2 &&
                            <div className={classes.businessFeaturesSection}>
                                <EcommerceBusinessFeaturesForCloud />
                            </div>}

                            <div>
                                <div className={classes.pickerRow}>
                                    <UiText
                                        className={classes.presetsSubTitle}
                                        variant={FontVariantToken.fontSizeH2}>
                                        {translate({ id: presetsOfferSubtitle })}
                                    </UiText>
                                    <div className={classes.picker}>
                                        <EcommerceCurrencyDropdown
                                            selectedCurrency={currentCurrency}
                                            setSelectedCurrency={setCurrentCurrency}
                                            productsPricesInAllCurrencies={productsPricesInAllCurrencies ?? []} />
                                    </div>
                                </div>
                                <RadioGroup
                                    aria-label="presets"
                                    name="presets"
                                    value={selectedPackageType}>
                                    {
                                        packagesData?.packages.map((skuPackage) => (
                                            skuCodeToOfferType[skuPackage.type] &&
                                            <div
                                                role="button"
                                                tabIndex={0}
                                                className={selectedPackageType === skuPackage.type ?
                                                    classes.selectedRadioCard : classes.radioCard}
                                                data-cy={skuPackage.type + '-PackageCard'}
                                                key={skuPackage.type}
                                                onClick={onSelectOption(skuPackage.type)}
                                                onKeyPress={onSelectOption(skuPackage.type)}
                                            >
                                                <div className={classes.packageDetails}>
                                                    <FormControlLabel
                                                        value={skuPackage.type}
                                                        control={<Radio />}
                                                        label={<UiText className={classes.packageName}>
                                                            {translate({ id: skuCodeToOfferType[skuPackage.type] })}
                                                        </UiText>} />
                                                    <div className={classes.packageInnerDetails}>
                                                        <UiText className={classes.packagePrice}>
                                                            {getPrice(skuPackage, currentSkuPackageDetails.planType)}
                                                        </UiText>
                                                        <UiText className={classes.planType}>
                                                            {translate({ id: planTypeText[currentSkuPackageDetails.planType] })}
                                                        </UiText>
                                                    </div>
                                                </div>

                                                <div className={classes.productDetailsContainer}>
                                                    {skuPackage.productQuantities.map((productQuantity) => (
                                                        <div
                                                            className={classes.productDetails}
                                                            key={productQuantity.code}>
                                                            <UiText className={classes.productDetailsQuantity}>
                                                                {productQuantity.quantity}
                                                            </UiText>
                                                            <UiText>
                                                                {translate({ id: `CLIENT_PRODUCT_${productQuantity.code }` })}
                                                            </UiText>
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className={classes.learnMore}>
                                                    <UiText>
                                                        <Link
                                                            component='button'
                                                            data-cy={'learn-more-' + skuPackage.type}
                                                            onClick={showDialogCallback(skuPackage.type)}
                                                            className={classes.learnMoreLink}
                                                            aria-label={translate({ id: `${skuCodeToOfferType[skuPackage.type]}_LEARN_MORE_ARIA_LABEL` })}>
                                                            {translate({ id: 'CLIENT_LEARN_MORE' })}
                                                        </Link>
                                                    </UiText>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </RadioGroup>
                            </div>

                            <div className={classes.dividerSection}>
                                <Divider className={classes.divider} />
                                <UiText className={classes.dividerText}>
                                    {translate({ id: 'CLIENT_OR' })}
                                </UiText>
                                <Divider className={classes.divider} />
                            </div>

                            <Button
                                className={classes.individualLicensesButton}
                                variant="outlined"
                                onClick={() => {
                                    isDirectBuyFlow ?
                                        navigate(getRoute(DirectBuyIndividualProducts)) :
                                        navigate(getRoute(BuyProIndividualProducts));
                                }}
                                data-cy='buy-individual-button'
                            >
                                {translate({ id: 'CLIENT_ECOMMERCE_BUY_INDIVIDUAL_LICENSES' })}
                            </Button>

                            {!enableEcommercePricingV2 &&
                                <div className={classes.businessFeaturesSection}>
                                    <EcommerceBusinessFeaturesForCloud />
                                </div>}

                        </div>
                        <div className={classes.summarySection}>
                            <div className={classes.salesLink}>
                                <UiText className={classes.buyEnterpriseText}>
                                    {translate({ id: 'CLIENT_ECOMMERCE_BUYING_FOR_AN_ENTERPRISE' })}
                                </UiText>
                                <EcommerceContactSalesLinkComponent />
                            </div>
                            <div className={classes.summaryWrapper}>
                                <div className={classes.summaryComponent}>
                                    {productsPricesInAllCurrencies && selectedPackage && (
                                        <EcommercePlanSummary
                                            isDirectBuyPagesSource={isDirectBuyFlow}
                                            {...currentSkuPackageDetails}
                                            products={selectedPackage.productQuantities}
                                            taxLoading={false}
                                            currency={currentCurrency}
                                            price={price}
                                            productsPricesInAllCurrencies={productsPricesInAllCurrencies}
                                            productPricesLoading={loading}
                                            isFirstSubscription
                                            isFormValid
                                            planPackage={selectedPackage}
                                        />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
    );
};

export const EcommercePresets: React.FC<{ isDirectBuyFlow: boolean }> = ({ isDirectBuyFlow }) => (
    <EcommerceProvider>
        <EcommercePresetsComponent isDirectBuyFlow={isDirectBuyFlow} />
        <UiSuspensefulOutlet />
    </EcommerceProvider>
);
EcommercePresets.displayName = 'EcommercePresetsComponent';

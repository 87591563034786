import { logEvent } from '@experiences/ab-testing';
import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import type { LanguageCulture } from '@experiences/locales';
import { useTranslatedCountries } from '@experiences/locales';
import {
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';
import {
    post,
    UiStorage,
    useAuthContext,
} from '@experiences/util';
import Cookies from 'js-cookie';
import {
    useCallback,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import type {
    IDirectBuyData,
    IHistoryStatus,
} from '../interfaces/ecommerce';
import { useEcommerceRegionFeatureFlags } from './EcommerceRegionFeatureFlags';
import {
    accountGlobalId,
    accountType,
    isAdminSelector,
    profile,
    userGlobalId,
} from './EcommerceSelectors';

const COUNTRY_CODE_KEY = 'countryCode';
const PLAN_TYPE_KEY = 'ecommerce_planType';
export const ANNUAL_PLAN_TYPE = 'ANNUAL_SUB_ANNUAL_PAY';
export const MONTHLY_PLAN_TYPE = 'ANNUAL_SUB_MONTHLY_PAY';
export const DIRECT_BUY_DATA = 'directBuyData';
export const DIRECT_BUY_ORDER_CONFIRMATION = 'directBuyOrderConfirmation';
export const DIRECT_BUY_FLOW = 'DirectBuy';
export const TRY_BUY_FLOW = 'TryBuy';
export const INCREMENTAL_FLOW = 'Incremental';
export const SKU_PACKAGE_DETAILS = 'skuPackageDetails';
export const MARKETO_DATA = 'marketoData';
export const CART_ABANDONMENT_SOURCE_KEY = 'cartAbandonmentSource';
export const DEFAULT_PRODUCT_CODES: string[] = [ 'RPADEVPRONU', 'RU' ];
export const DEFAULT_PRICING_V2_PRODUCT_CODES: string[] = [ 'CBNU', 'PLUSNU', 'PRONU' ];
export const ADDON_PRODUCT_CODES: string[] = [ 'CTZDEVNU', 'UNATT', 'ATTUNU' ];
export const ADDON_PRICING_V2_PRODUCT_CODES: string[] = [ 'UNATT', 'RU' ];
export const INDIVIDUAL_PRODUCT_CODES: string[] = [ ...DEFAULT_PRODUCT_CODES, ...ADDON_PRODUCT_CODES ];
export const ECOMMERCE_PAYMENT_FAILED_BANNER_KEY =
  'Portal.DisplayEcommercePaymentFailedBanner';
export const ECOMMERCE_PAYMENT_FAILED_POPUP_KEY =
    'Ecommerce.DisplayEcommercePaymentFailedPopup';
// eslint-disable-next-line max-len
export const JAPAN_COMMERCIAL_TRANSACTION_ACT_PATH =
  'https://www.uipath.com/hubfs/legalspot/jp-uipath-the-indication-of-advertisement-based-on-the-specified-commercial-transactions-act.pdf';

export const europeCountries = [
    'AX',
    'AL',
    'AD',
    'AM',
    'AT',
    'AZ',
    'BY',
    'BE',
    'BA',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FO',
    'FI',
    'FR',
    'GB',
    'GE',
    'DE',
    'GI',
    'GR',
    'GL',
    'HU',
    'VA',
    'IS',
    'IE',
    'IM',
    'IT',
    'XK',
    'LV',
    'LI',
    'LT',
    'LU',
    'MK',
    'MD',
    'MC',
    'ME',
    'MT',
    'NL',
    'NO',
    'PL',
    'PT',
    'RO',
    'RU',
    'SM',
    'CS',
    'RS',
    'SK',
    'SI',
    'SJ',
    'ES',
    'SE',
    'CH',
    'TR',
    'TC',
    'UA',
];
export const telemetryUrl = '/api/telemetry';
export const telemetryPublicUrl = '/api/telemetryPublic';
export const ECOMMERCE_PRODUCT_CODES = [
    'RPADEVPRONU',
    'RU',
    'CTZDEVNU',
    'ATTUNU',
    'UNATT',
];
export const getPriceString = (
    price: number,
    currency: string,
    language: string,
    tax?: number,
    withDecimals: boolean = true
) => {
    if (currency.toUpperCase() === 'JPY') {
        return `${(tax ? price + tax : price).toLocaleString(language, {
            style: 'currency',
            currency,
        })}`;
    }

    return `${(tax ? (price + tax) / 100 : price / 100).toLocaleString(language, {
        style: 'currency',
        currency,
        minimumFractionDigits: withDecimals ? 2 : 0,
        maximumFractionDigits: withDecimals ? 2 : 0,
    })}`;
};

export const getTaxPercentageString = (taxRate: number, language: string) =>
    `${(taxRate / 100).toLocaleString(language, {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
    })}`;

export const storeCountryCode = (countryCode: string) =>
    sessionStorage.setItem(COUNTRY_CODE_KEY, countryCode);

export const getStoredCountryCode = () =>
    sessionStorage.getItem(COUNTRY_CODE_KEY) ?? '';

export const storePlanType = (planType: string) =>
    sessionStorage.setItem(PLAN_TYPE_KEY, planType);

export const getStoredPlanType = () =>
    sessionStorage.getItem(PLAN_TYPE_KEY) ?? MONTHLY_PLAN_TYPE;

export const useIsDirectBuyInProgressFlow = () => !!UiStorage.getItem(DIRECT_BUY_DATA);

export const useIsSignupDirectBuyFlow = () => {
    const accountId = useSelector(accountGlobalId);
    const isDirectBuyInProgress = useIsDirectBuyInProgressFlow();
    return (
        (isDirectBuyInProgress ||
      !!UiStorage.getItem(DIRECT_BUY_ORDER_CONFIRMATION)) &&
    !accountId
    );
};

export const useIsEcommerceEnabled = () => {
    const { accountCountryCode } = useTranslatedCountries();
    const isAdmin = useSelector(isAdminSelector);
    const EnableEcommerceIntegration = getFeatureFlagValue(
        Features.EnableEcommerceIntegration.name
    );
    const {
        EnableEcommerceInUs, EnableEcommerceInEurope, EnableEcommerceInWorld, EnableEcommerceInJapan,
    } = useEcommerceRegionFeatureFlags();

    const isEcommerceEnabled = useMemo(
        () =>
            isAdmin &&
      EnableEcommerceIntegration &&
      ((EnableEcommerceInUs &&
        (accountCountryCode === 'US' || accountCountryCode === 'CA')) ||
        (EnableEcommerceInEurope &&
          europeCountries.includes(accountCountryCode)) ||
        (EnableEcommerceInWorld &&
          accountCountryCode !== 'US' &&
          accountCountryCode !== 'CA' &&
          !europeCountries.includes(accountCountryCode) &&
          accountCountryCode !== 'JP') ||
        (EnableEcommerceInJapan && accountCountryCode === 'JP')),
        [
            isAdmin,
            EnableEcommerceIntegration,
            EnableEcommerceInUs,
            accountCountryCode,
            EnableEcommerceInEurope,
            EnableEcommerceInWorld,
            EnableEcommerceInJapan,
        ]
    );

    return isEcommerceEnabled;
};

export const useEcommerceEnabledCountries = () => {
    const {
        accountCountryCode,
        translatedCountries,
        countryCodeCountries,
        englishCountryCodeCountries,
    } = useTranslatedCountries();

    const EnableEcommerceBillingFromRomania = getFeatureFlagValue(
        Features.EnableEcommerceBillingFromRomania.name
    );

    const {
        EnableEcommerceInUs, EnableEcommerceInEurope, EnableEcommerceInWorld, EnableEcommerceInJapan,
    } = useEcommerceRegionFeatureFlags();

    const availableCountryCodes = useMemo(() => {
        const countryCodes: string[] = [];
        if (EnableEcommerceInUs) {
            countryCodes.push('US');
            countryCodes.push('CA');
        }
        if (EnableEcommerceInEurope) {
            countryCodes.push(...europeCountries);
        }
        if (EnableEcommerceInWorld) {
            countryCodes.push(
                ...Object.keys(englishCountryCodeCountries).filter(
                    (code) =>
                        code !== 'US' &&
            code !== 'CA' &&
            code !== 'JP' &&
            !europeCountries.includes(code)
                )
            );
        }
        if (EnableEcommerceInJapan) {
            countryCodes.push('JP');
        }

        return countryCodes;
    }, [ EnableEcommerceInEurope, EnableEcommerceInJapan, EnableEcommerceInUs, EnableEcommerceInWorld, englishCountryCodeCountries ]);

    const filteredCountryCodeCountries = useMemo(
        () => Object.fromEntries(
            Object.entries(countryCodeCountries).filter((entry) =>
                availableCountryCodes.includes(entry[0]) && (EnableEcommerceBillingFromRomania || entry[0] !== 'RO')
                // PLT-63301 - Re-enable Romania
            )
        ),
        [ countryCodeCountries, availableCountryCodes, EnableEcommerceBillingFromRomania ]
    );

    const filteredTranslatedCountries = useMemo(() => {
        const filteredCountryNames = Object.values(filteredCountryCodeCountries);
        return Object.fromEntries(
            Object.entries(translatedCountries).filter((entry) =>
                filteredCountryNames.includes(entry[1])
            )
        );
    }, [ translatedCountries, filteredCountryCodeCountries ]);

    return {
        accountCountryCode,
        filteredTranslatedCountries,
        filteredCountryCodeCountries,
        englishCountryCodeCountries,
    };
};

export const useEcommerceTelemetry = (isDirectBuyPublicFlow = false) => {
    const accountId = useSelector(accountGlobalId);
    const userId = useSelector(userGlobalId);
    const subscriptionType = useSelector(accountType);
    const currentProfile = useSelector(profile);
    const commitmentType = getStoredPlanType();
    const {
        claims, token,
    } = useAuthContext();
    const email = currentProfile?.emailId
        ? currentProfile.emailId
        : claims?.email;

    return useCallback(
        async (name: string, details: any = {}) => {
            const logEventUrl = isDirectBuyPublicFlow ? `${telemetryPublicUrl}/logEcommerceCustomEvent` : `${telemetryUrl}/logCustomEvent`;
            try {
                const isTestAccount = !!email?.endsWith('@uipath-qa.com') || !!email?.endsWith('@uipath.com');
                const properties = {
                    ApplicationName: 'UiPath.AutomationCloud',
                    CloudOrganizationId: accountId,
                    BusinessAreaName: 'UiPath.Ecommerce',
                    CloudUserId: userId,
                    SubscriptionType: subscriptionType,
                    CommitmentType: commitmentType,
                    IsTestAccount: isTestAccount,
                    GAId: Cookies.get('_ga'),
                    ...details,
                };
                await post(logEventUrl, {
                    body: {
                        name,
                        properties,
                    },
                    accessToken: token,
                });
                if (!isTestAccount) {
                    await logEvent(name, null, properties, { userIdentifier: userId }, token);
                }
            } catch (e) {
                portalTelemetry.trackTrace({
                    message: `Error tracking ecommerce event ${name}`,
                    severityLevel: SeverityLevel.Major,
                });

            }
        },
        [ isDirectBuyPublicFlow, email, accountId, userId, subscriptionType, commitmentType, token ]
    );
};

export function setEcommerceParamsInStorage(
    ecommerceRedirect: string,
    directBuyData?: IDirectBuyData,
    source?: string,
) {
    const hasDirectBuyParam = !!directBuyData?.productQuantities;
    if (hasDirectBuyParam) {
        UiStorage.setItem(DIRECT_BUY_DATA, JSON.stringify(directBuyData));
    } else {
        UiStorage.setItem('ecommerceRedirect', ecommerceRedirect);
        if (source) {
            UiStorage.setItem('source', source);
        }
        UiStorage.removeItem(DIRECT_BUY_DATA);
    }
}

export function formatDate(
    dateToFormat: string | undefined,
    language: LanguageCulture
) {
    const locales = language === 'en' ? 'en-US' : language;
    return dateToFormat
        ? new Date(dateToFormat).toLocaleDateString(locales, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        })
        : dateToFormat;
}

export function getLicenseInfoUiState(
    hasLicenceUpdateSubscriptionSchedule: boolean,
    hasPriceChangesSubscriptionSchedule: boolean,
    upcomingBillValue?: number,
    ecommerceHistoryStatus?: IHistoryStatus
) {

    const hasLicensePendingChanges = hasPriceChangesSubscriptionSchedule || hasLicenceUpdateSubscriptionSchedule;

    const shouldEnableUpdateLicenseButton =
    (!hasLicensePendingChanges) &&
    upcomingBillValue &&
    ecommerceHistoryStatus?.firstPaymentStatus !== 'PAYMENT_OPEN' &&
    ecommerceHistoryStatus?.incrementalPaymentStatus !== 'PAYMENT_OPEN' &&
    ecommerceHistoryStatus?.recurringPaymentStatus !== 'PAYMENT_OPEN';

    const updateLicenseButtonTooltipTextId = hasLicensePendingChanges
        ? 'CLIENT_ECOMMERCE_LICENSE_PENDING_CHANGE_TOOLTIP_TEXT'
        : 'CLIENT_ECOMMERCE_UPDATE_LICENSE_WHEN_PENDING_PAYMENT_TOOLTIP_TEXT';

    const shouldHideSubscriptionCancelOption = hasLicensePendingChanges || hasPriceChangesSubscriptionSchedule;

    const paymentInfoLinkText = shouldHideSubscriptionCancelOption
        ? 'CLIENT_UPDATE_PAYMENT_INFO_VIEW_BILLING_HISTORY'
        : 'CLIENT_UPDATE_PAYMENT_INFO_VIEW_BILLING_HISTORY_CANCEL_PLAN';

    return {
        shouldEnableUpdateLicenseButton,
        updateLicenseButtonTooltipTextId,
        shouldHideSubscriptionCancelOption,
        paymentInfoLinkText,
    };
}

import type {
    IGroupAllocations,
    IUserWithExplicitAllocations,
} from '../../../common/interfaces/licenses';

export enum ServiceLicenseStatus {
    Available = '_0',
    Used = '_1',
    NotAvailable = '_2',
}

export interface IAccountProductDto {
    code: string;
    type: string;
    quantity: number;
}

interface IAccountLicenseDto {
    accountId: string;
    bundleCode: string;
    subscriptionCode: string;
    subscriptionPlan: string;
    licenseCode: string;
    licenseStatus: string;
    products: IAccountProductDto[];
    type: string;
    parentLicenseCode?: string;
}

export interface IProductAvailability {
    code: string;
    type: string;
    availableQuantity: number;
    totalQuantity: number;
    consumedQuantity: number;
}

export interface IOrganizationLicenseWithAvailableProducts {
    license?: IAccountLicenseDto;
    productsWithAvailability: IProductAvailability[];
}

export interface IProductQuantity {
    code: string;
    quantity: number;
}

export interface IAllocateOrganizationLicenseRequest {
    productQuantities: IProductQuantity[];
}

export interface IOrganizationLicenseGroup {
    id: string;
    products: IOrganizationLicenseProduct[];
}

export interface IOrganizationLicenseProduct {
    code: string;
    type: string;
    quantity: number;
    available: number;
    consumedQuantity: number;
}

export interface IUserWithExplicitAllocationsPagedResult {
    totalCount: number;
    results: IUserWithExplicitAllocations[];
}

export interface IGroupAllocationsPagedResult {
    totalCount: number;
    results: IGroupAllocations[];
}

export interface IActivationData {
    licenseCode: string;
}

export enum LicenseAllocationMethod {
    EXPLICIT = 'explicitlyAllocated',
    GROUP = 'groupAllocation',
}

interface IUserBundleQuota {
    userBundleLicense: string;
    quotaConfig: {
        enabled: boolean;
        value: number;
    };
}

export interface IUserLicenseExplicitAllocation {
    userBundleLicenses: string[];
    useExternalLicense: boolean;
    licenseAllocationMethod: LicenseAllocationMethod;
    userBundleQuotas: IUserBundleQuota[];
}

export interface IConfigureOrganizationLicenses {
    products: { [code: string]: string | number | undefined } | undefined;
}
